import React from "react";
import { useTranslation } from "react-i18next";
import FontAwesome from "react-fontawesome";
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import IconLogo from "../../../../config/images/Neutral Markets Logomark - Light.png";
import { MenuItemWithTooltip, MenuSection } from "../../Menu";

const CollapsedSideNav = ({
  expandedMenus,
  onMenuToggle,
  handleNavLinkClick,
  handleCollapseOthers,
  permissions,
  showMarketControl,
  handleOpenMarkets,
  handleCloseMarkets,
  handleShowAboutModel,
  login,
  keycloak,
  unapprovedMarkCount,
  unapprovedTradeCount,
  selectedDrawerItem,
  onSelectItem,
  onToggleSidebar,
  activeMenuItems,
}) => {
  const { t } = useTranslation();

  const renderMenuItem = ({
    to,
    icon,
    text,
    onClick,
    isButton = false,
    isSubmenuItem = false,
    parentIds = [],
    className = "",
    parentId,
    menuId = "",
  }) => {
    // Add a class that indicates the nesting level for proper CSS indentation
    const nestingLevel = parentIds.length;
    const nestingClassName =
      nestingLevel > 0 ? `nesting-level-${nestingLevel}` : "";

    return (
      <MenuItemWithTooltip
        to={to}
        icon={icon}
        text={text}
        onClick={(e) => {
          if (onClick) onClick(e);
          // Only update selected item for submenu items
          if (isSubmenuItem && onSelectItem) {
            onSelectItem({ icon, text, parentId });
          }
          // Only close drawers when clicking a direct navigation item
          // Don't close drawers when clicking submenu items
          if (to && !isButton && !isSubmenuItem) {
            onMenuToggle(""); // Pass empty string to close all drawers
          }
        }}
        isButton={isButton}
        isSubmenuItem={isSubmenuItem}
        isExpanded={false}
        parentIds={parentIds}
        onCollapseOthers={handleCollapseOthers}
        onSelectItem={isSubmenuItem ? onSelectItem : undefined}
        parentId={parentId}
        className={`${className} ${nestingClassName}`}
        activeMenuItems={activeMenuItems}
        // Only show tooltips for non-drawer items
        showTooltip={!isSubmenuItem}
      />
    );
  };

  const renderMenuSection = (
    title,
    icon,
    children,
    menuId = "",
    parentIds = [],
    isSubmenuItem = false
  ) => {
    // Create new parentIds array including the current menuId
    const newParentIds = menuId ? [...parentIds, menuId] : [...parentIds];

    return (
      <MenuSection
        title={title}
        icon={icon}
        isExpanded={expandedMenus.includes(menuId)}
        onToggle={(e) => {
          // If this is a click event, toggle the menu
          if (e && e.type === "click") {
            onMenuToggle(menuId, e);
          }
          // If this is a hover event, pass it through (only for top-level items)
          else if (e && e.type === "hover" && !isSubmenuItem) {
            // Don't toggle if drawer is already expanded
            if (!expandedMenus.includes(menuId)) {
              onMenuToggle(menuId, e);
            }
          }
          // If this is a mouseleave event, pass it through (only for top-level items)
          else if (e && e.type === "mouseleave" && !isSubmenuItem) {
            // Only close if it's currently expanded
            if (expandedMenus.includes(menuId)) {
              onMenuToggle(menuId, e);
            }
          }
          // If this is a nested submenu toggle, pass it through
          else if (e && e.type === "nestedSubmenuToggle") {
            onMenuToggle(menuId, e);
          }
          // Backward compatibility for simple hover events (only for top-level items)
          else if (!e && !isSubmenuItem) {
            // Don't toggle if drawer is already expanded
            if (!expandedMenus.includes(menuId)) {
              onMenuToggle(menuId, true);
            }
          }
        }}
        menuId={menuId}
        parentIds={parentIds}
        sidebarExpanded={false}
        onCollapseOthers={handleCollapseOthers}
        activeMenuItems={activeMenuItems}
        enableHoverBehavior={!isSubmenuItem} // Disable hover behavior for submenu items
        isSubmenuItem={isSubmenuItem}
      >
        {typeof children === "function" ? children(newParentIds) : children}
      </MenuSection>
    );
  };

  return (
    <>
      <div className="sidebar-header">
        <div className="sidebar-brand">
          <Link to="/">
            <img src={IconLogo} alt="Neutral Markets" className="icon-logo" />
          </Link>
        </div>
        <div className="toggle-container">
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip>{t("ExpandSidebar")}</Tooltip>}
          >
            <button
              className="toggle-button"
              onClick={onToggleSidebar}
              aria-label={t("ExpandSidebar")}
            >
              <FontAwesome name="chevron-right" />
            </button>
          </OverlayTrigger>
        </div>
      </div>

      <div className="sidebar-content">
        <div className="sidebar-menu">
          {/* Home */}
          {renderMenuItem({
            to: "/",
            icon: "home",
            text: t("Home"),
            onClick: handleNavLinkClick,
          })}

          {/* Alberta Energy Daily Report */}
          {renderMenuItem({
            to: "/albertaenergydaily",
            icon: "plug-circle-bolt",
            text: t("AlbertaEnergyDaily"),
            onClick: handleNavLinkClick,
          })}

          {/* Market Data (Flattened) */}
          {renderMenuItem({
            to: "/marketdata",
            icon: "chart-column",
            text: t("DailySummary"),
            onClick: handleNavLinkClick,
          })}
          {renderMenuItem({
            to: "/historicalmarketdata",
            icon: "chart-line",
            text: t("HistoricalMarketData"),
            onClick: handleNavLinkClick,
          })}
          {renderMenuItem({
            to: "/historicaltrades",
            icon: "arrow-right-arrow-left",
            text: t("HistoricalTrades"),
            onClick: handleNavLinkClick,
          })}

          {/* Trading Menu */}
          {permissions.showTrading &&
            renderMenuItem({
              to: "/trading",
              icon: "people-arrows",
              text: t("Trading"),
              onClick: handleNavLinkClick,
            })}

          {/* Alberta Electricity Dashboard */}
          {renderMenuItem({
            to: "/dashboards/albertaelectricity",
            icon: "bolt",
            text: t("AlbertaElectricityDashboard"),
            onClick: handleNavLinkClick,
          })}

          {/* Alberta Environmental Section - Moved to top level */}
          {renderMenuSection(
            t("AlbertaEnvironmental"),
            "leaf",
            <>
              {renderMenuItem({
                to: "/dashboards/albertaenvironmental",
                icon: "leaf",
                text: t("Dashboard"),
                onClick: handleNavLinkClick,
                isSubmenuItem: true,
                parentIds: ["albertaEnvironmental"],
              })}
              {renderMenuItem({
                to: "/dashboards/albertaenvironmental/trades",
                icon: "arrow-right-arrow-left",
                text: t("Trades"),
                onClick: handleNavLinkClick,
                isSubmenuItem: true,
                parentIds: ["albertaEnvironmental"],
              })}
              {renderMenuItem({
                to: "/dashboards/albertaenvironmental/registry",
                icon: "book",
                text: t("Registry"),
                onClick: handleNavLinkClick,
                isSubmenuItem: true,
                parentIds: ["albertaEnvironmental"],
              })}
              {renderMenuItem({
                to: "/dashboards/albertaenvironmental/issues",
                icon: "seedling",
                text: t("Issues"),
                onClick: handleNavLinkClick,
                isSubmenuItem: true,
                parentIds: ["albertaEnvironmental"],
              })}
              {renderMenuItem({
                to: "/dashboards/albertaenvironmental/statuschanges",
                icon: "arrows-turn-to-dots",
                text: t("StatusChanges"),
                onClick: handleNavLinkClick,
                isSubmenuItem: true,
                parentIds: ["albertaEnvironmental"],
              })}
            </>,
            "albertaEnvironmental",
            [],
            false
          )}

          {/* Manage Data Section */}
          {renderMenuSection(
            t("ManageData"),
            "database",
            <>
              {/* Submit Items */}
              {renderMenuItem({
                to: "/marketdata/submitmarks",
                icon: "comment-dollar",
                text: t("MarketDataSubmitMarks"),
                onClick: handleNavLinkClick,
                isSubmenuItem: true,
                parentIds: ["manageData"],
              })}
              {renderMenuItem({
                to: "/marketdata/submittrades",
                icon: "comments-dollar",
                text: t("MarketDataSubmitTrades"),
                onClick: handleNavLinkClick,
                isSubmenuItem: true,
                parentIds: ["manageData"],
              })}
              {renderMenuItem({
                to: "/marketdata/submitexternaldata",
                icon: "comments-dollar",
                text: t("MarketDataSubmitExternalData"),
                onClick: handleNavLinkClick,
                isSubmenuItem: true,
                parentIds: ["manageData"],
              })}

              <div className="submenu-divider" />

              {/* Review Items */}
              {renderMenuItem({
                to: "/marketdata/reviewmarks",
                icon: "magnifying-glass-dollar",
                text: (
                  <>
                    {t("MarketDataReviewMarks")}
                    {unapprovedMarkCount > 0 && (
                      <Badge pill bg="warning" text="dark" className="ms-2">
                        {unapprovedMarkCount}
                      </Badge>
                    )}
                  </>
                ),
                onClick: handleNavLinkClick,
                isSubmenuItem: true,
                parentIds: ["manageData"],
              })}
              {renderMenuItem({
                to: "/marketdata/reviewtrades",
                icon: "magnifying-glass-arrow-right",
                text: (
                  <>
                    {t("MarketDataReviewTrades")}
                    {unapprovedTradeCount > 0 && (
                      <Badge pill bg="warning" text="dark" className="ms-2">
                        {unapprovedTradeCount}
                      </Badge>
                    )}
                  </>
                ),
                onClick: handleNavLinkClick,
                isSubmenuItem: true,
                parentIds: ["manageData"],
              })}
            </>,
            "manageData"
          )}

          {/* Admin Section */}
          {permissions.authenticated &&
            permissions.showAdmin &&
            renderMenuSection(
              t("Admin"),
              "shield",
              <>
                {permissions.showUserAdmin &&
                  renderMenuItem({
                    to: "/admin/auth",
                    icon: "users",
                    text: t("UserAdmin"),
                    onClick: handleNavLinkClick,
                    isSubmenuItem: true,
                    parentIds: ["admin"],
                  })}
                {permissions.showProductAdmin &&
                  renderMenuItem({
                    to: "/admin/products",
                    icon: "coins",
                    text: t("ProductAdmin"),
                    onClick: handleNavLinkClick,
                    isSubmenuItem: true,
                    parentIds: ["admin"],
                  })}
                {permissions.showMarketAdmin &&
                  renderMenuItem({
                    to: "/admin/markets",
                    icon: "store",
                    text: t("MarketAdmin"),
                    onClick: handleNavLinkClick,
                    isSubmenuItem: true,
                    parentIds: ["admin"],
                  })}
                {permissions.showTradingAdmin &&
                  renderMenuItem({
                    to: "/admin/trading",
                    icon: "gears",
                    text: t("TradingAdmin"),
                    onClick: handleNavLinkClick,
                    isSubmenuItem: true,
                    parentIds: ["admin"],
                  })}
                {showMarketControl &&
                  renderMenuSection(
                    t("MarketControl"),
                    "sliders",
                    (parentIds) => (
                      <>
                        {renderMenuItem({
                          icon: "toggle-on",
                          text: t("OpenMarkets"),
                          onClick: handleOpenMarkets,
                          isButton: true,
                          isSubmenuItem: true,
                          parentIds: parentIds,
                          menuId: "admin.marketControl",
                        })}
                        {renderMenuItem({
                          icon: "toggle-off",
                          text: t("CloseMarkets"),
                          onClick: handleCloseMarkets,
                          isButton: true,
                          isSubmenuItem: true,
                          parentIds: parentIds,
                          menuId: "admin.marketControl",
                        })}
                        {renderMenuItem({
                          icon: "arrows-rotate",
                          text: t("ResetMarkets"),
                          onClick: () => console.log("Reset markets"),
                          isButton: true,
                          isSubmenuItem: true,
                          parentIds: parentIds,
                          menuId: "admin.marketControl.advanced",
                        })}
                      </>
                    ),
                    "admin.marketControl",
                    ["admin"]
                  )}
              </>,
              "admin"
            )}
        </div>

        {/* Footer */}
        <div className="sidebar-footer">
          {/* User Profile */}
          {renderMenuItem({
            to: "/profile",
            icon: "user",
            text: login,
            onClick: handleNavLinkClick,
          })}

          {/* About */}
          {renderMenuItem({
            icon: "info-circle",
            text: t("About"),
            onClick: handleShowAboutModel,
            isButton: true,
          })}

          {/* Logout */}
          {renderMenuItem({
            icon: "sign-out",
            text: t("Logout"),
            onClick: () => keycloak.logout(),
            isButton: true,
          })}
        </div>
      </div>
    </>
  );
};

export default CollapsedSideNav;
