import React, { useState } from "react";
import { Tabs, Tab, Nav } from "react-bootstrap";
import { usePermitted } from "../../common/components/permissions/permissions";
import UserAccountAdmin from "./userAccounts/userAccountAdmin";
import OrganizationAdmin from "./organizations/organizationAdmin";
import RoleAdmin from "./roles/roleAdmin";
import SubscriptionAdmin from "./subscriptions/subscriptionAdmin";
import { useTranslation } from "react-i18next";

const AuthAdmin = () => {
  const [activeTab, setActiveTab] = useState("1");

  const queryUserAccounts = usePermitted(["api-query-user-accounts"]);
  const manageUserAccounts = usePermitted(["api-manage-user-accounts"]);
  const queryOrganizations = usePermitted(["api-query-organizations"]);
  const manageOrganizations = usePermitted(["api-manage-organizations"]);
  const queryRoles = usePermitted(["api-query-roles"]);
  const manageRoles = usePermitted(["api-manage-roles"]);
  const queryOrganizationSubscriptions = usePermitted([
    "api-query-organization-market-subscriptions",
  ]);
  const manageOrganizationSubscriptions = usePermitted([
    "api-manage-organization-market-subscriptions",
  ]);
  const { t } = useTranslation(["auth"]);

  return (
    <div className="tabs-container auth-container">
      <Nav
        variant="tabs"
        className="nav-dark"
        style={{ display: "flex", flexDirection: "row", width: "100%" }}
      >
        {queryUserAccounts && (
          <Nav.Item>
            <Nav.Link
              active={activeTab === "1"}
              onClick={() => setActiveTab("1")}
            >
              {t("Users")}
            </Nav.Link>
          </Nav.Item>
        )}
        {queryOrganizations && (
          <Nav.Item>
            <Nav.Link
              active={activeTab === "2"}
              onClick={() => setActiveTab("2")}
            >
              {t("Organizations")}
            </Nav.Link>
          </Nav.Item>
        )}
        {queryRoles && (
          <Nav.Item>
            <Nav.Link
              active={activeTab === "3"}
              onClick={() => setActiveTab("3")}
            >
              {t("Roles")}
            </Nav.Link>
          </Nav.Item>
        )}
        {queryOrganizationSubscriptions && (
          <Nav.Item>
            <Nav.Link
              active={activeTab === "4"}
              onClick={() => setActiveTab("4")}
            >
              {t("Subscriptions")}
            </Nav.Link>
          </Nav.Item>
        )}
      </Nav>

      <div
        className="tab-content"
        style={{ display: "block", width: "100%", height: "calc(100% - 41px)" }}
      >
        {activeTab === "1" && queryUserAccounts && (
          <div className="tab-pane fade show active" style={{ height: "100%" }}>
            <UserAccountAdmin allowEdit={manageUserAccounts} />
          </div>
        )}
        {activeTab === "2" && queryOrganizations && (
          <div className="tab-pane fade show active" style={{ height: "100%" }}>
            <OrganizationAdmin allowEdit={manageOrganizations} />
          </div>
        )}
        {activeTab === "3" && queryRoles && (
          <div className="tab-pane fade show active" style={{ height: "100%" }}>
            <RoleAdmin allowEdit={manageRoles} />
          </div>
        )}
        {activeTab === "4" && queryOrganizationSubscriptions && (
          <div className="tab-pane fade show active" style={{ height: "100%" }}>
            <SubscriptionAdmin allowEdit={manageOrganizationSubscriptions} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthAdmin;

// class AuthAdmin extends Component {

//     render() {
//         return (

//         );
//     }
// }

// export default AuthAdmin;
