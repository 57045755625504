import React from "react";
import Joi from "joi-browser";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { Tabs, Tab } from "react-bootstrap";
import {
  getPriceBasis,
  addPriceBasis,
  updatePriceBasis,
} from "../../../common/services/markets/priceBasisService";
import Form from "../../../common/components/form/form";
import Loading from "../../../common/components/loading/loading";
import PriceBasisHistory from "./priceBasisHistory";
import { withTranslation } from "react-i18next";

class PriceBasis extends Form {
  state = {
    data: {
      priceBasisId: "",
      name: "",
      description: "",
      priceBasisClassId: "",
      active: "",
      eventId: "",
    },
    createdDate: "",
    updatedDate: "",
    errors: {},
    isLoading: true,
    isNew: true,
  };

  schema = {
    priceBasisId: Joi.string(),
    name: Joi.string().required().label(this.props.t("PriceBasisName")),
    description: Joi.string()
      .required()
      .label(this.props.t("PriceBasisDescription")),
    active: Joi.boolean().label(this.props.t("Active")),
    eventId: Joi.string(),
  };

  async componentDidMount() {
    //try{
    const { priceBasisId, isNew } = this.props;
    this.setState({ isNew: isNew });
    var priceBasis = {};

    if (!isNew) {
      priceBasis = await getPriceBasis(priceBasisId);
      priceBasis.eventId = uuid();
    } //New User Add
    else {
      priceBasis = {
        priceBasisId: priceBasisId,
        name: "",
        description: "",
        commoditiyClassId: "",
        active: true,
        eventId: uuid(),
      };
    }
    this.setState({ data: this.mapToViewModel(priceBasis), isLoading: false });

    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    this.setState({
      createdDate: new Date(priceBasis.createdDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      updatedDate: new Date(priceBasis.updatedDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
    });

    // }
    // catch(ex)
    // {
    //     //Handle Erorr..
    // }
  }

  mapToViewModel(priceBasis) {
    return {
      priceBasisId: priceBasis.priceBasisId,
      name: priceBasis.name,
      description: priceBasis.description,
      active: priceBasis.active,
      eventId: priceBasis.eventId,
    };
  }

  doSubmit = async () => {
    const { t, onRename } = this.props;
    const { isNew } = this.state;

    try {
      //Reset the event ID
      const data = { ...this.state.data };
      data.eventId = uuid();
      this.setState({ data: data });

      if (!isNew) {
        await updatePriceBasis(this.state.data);
        toast.success(t("PriceBasisUpdated"));
      } else {
        await addPriceBasis(this.state.data);
        this.setState({ isNew: false });
        onRename(this.state.data.priceBasisId, this.state.data.name);
        toast.success(t("PriceBasisAdded"));
      }

      //Call back to the select list to allow it to update.
      this.props.submitCallbackFunction(this.state.data);
    } catch (ex) {
      console.log(t("ErrorSaving"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        if (errorList) {
          var errorKeys = Object.keys(ex.response.data.errors);
          errorKeys.forEach(function (key) {
            errorList[key].map((error) => {
              toast.error(t("ErrorSaving") + " " + key + ": " + error);
              return null;
            });
          });
        } else {
          toast.error(t("ErrorSaving") + " " + ex.response.data);
        }
        const errors = { ...this.state.errors };
        errors.Name = ex.response.data;
        toast.error(ex.response.data.errors.title);
        this.setState({ errors });
      }
    }
  };

  render() {
    const { isLoading, isNew, createdDate, updatedDate } = this.state;

    if (isLoading) return <Loading />;

    const { t, priceBasisId, allowEdit } = this.props;

    return (
      <div className="container-fluid">
        <form className="form-inline" onSubmit={this.handleSubmit}>
          <fieldset disabled={allowEdit ? "" : "disabled"}>
            <div>
              {this.renderSwitch("active", t("Active"))}
              {this.renderInput("name", t("Name"))}
              {this.renderInput("description", t("Description"))}
              {allowEdit && this.renderButton(t("Save"))}
              <div className="form-footnote">
                {createdDate !== "Invalid Date" && (
                  <div>
                    {t("Created")} {createdDate}
                  </div>
                )}
                {updatedDate !== "Invalid Date" && (
                  <div>
                    {t("Updated")} {updatedDate}
                  </div>
                )}
              </div>
            </div>
          </fieldset>
        </form>
        {!isNew && (
          <div>
            <Tabs
              className="nav-dark nav-tabs-sm"
              defaultActiveKey={1}
              id="useraccount-tabs"
              mountOnEnter={true}
              unmountOnExit={true}
            >
              <Tab eventKey={1} title={t("History")}>
                <PriceBasisHistory priceBasisId={priceBasisId} />
              </Tab>
            </Tabs>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation(["markets"])(PriceBasis);
