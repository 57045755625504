import React, { Component } from "react";
import * as FlexLayout from "flexlayout-react";
import OrganizationSubscriptions from "./organizationSubscriptions";
import OrganizationSubscription from "./organizationSubscription";
import UserAccountSubscriptions from "./userAccountSubscriptions";
import UserAccountSubscription from "./userAccountSubscription";
import { withTranslation } from "react-i18next";

class SubscriptionAdmin extends Component {
  LAYOUT = {
    global: {
      minWidth: 100,
      minHeight: 100,
    },
    borders: [],
    layout: {
      type: "row",
      children: [
        {
          type: "tabset",
          weight: 50,
          enableMaximize: false,
          children: [
            {
              type: "tab",
              name: this.props.t("Organizations"),
              enableClose: false,
              component: "OrganizationSubscriptions",
            },
            {
              type: "tab",
              name: this.props.t("UserAccounts"),
              enableClose: false,
              component: "UserAccountSubscriptions",
            },
          ],
        },
        {
          //Figure out how to make this persistent even when empty.
          type: "tabset",
          id: "1",
          weight: 50,
          enableDeleteWhenEmpty: false,
          enableMaximize: false,
          children: [
            // {
            //   type: "tab",
            //   name: "User",
            //   visible: false,
            //   component: "Placeholder"
            // }
          ],
        },
      ],
    },
  };

  state = {
    model: FlexLayout.Model.fromJson(this.LAYOUT),
  };

  handleOrganizationSubscriptionSelect = (
    OrganizationSubscription,
    submitCallbackFunction,
    isNew
  ) => {
    const { organizationSubscriptionId, organizationName, subscribableName } =
      OrganizationSubscription;

    try {
      this.state.model.doAction(
        FlexLayout.Actions.addNode(
          {
            type: "tab",
            component: "OrganizationSubscription",
            name: isNew
              ? `New Organization Market Subscription`
              : `${organizationName} | ${subscribableName}`,
            id: `${organizationSubscriptionId}`,
            config: {
              organizationSubscriptionId,
              submitCallbackFunction,
              isNew,
            },
          },
          "1",
          FlexLayout.DockLocation.CENTER,
          0,
          true
        )
      );
    } catch {
      this.state.model.doAction(
        FlexLayout.Actions.selectTab(organizationSubscriptionId)
      );
    }
  };

  handleUserAccountSubscriptionSelect = (
    UserAccountSubscription,
    submitCallbackFunction,
    isNew
  ) => {
    const { userAccountSubscriptionId, organizationSubscriptionName, login } =
      UserAccountSubscription;

    try {
      this.state.model.doAction(
        FlexLayout.Actions.addNode(
          {
            type: "tab",
            component: "UserAccountSubscription",
            name: isNew
              ? `New User Market Subscription`
              : `${login} | ${organizationSubscriptionName}`,
            id: `${userAccountSubscriptionId}`,
            config: {
              userAccountSubscriptionId,
              submitCallbackFunction,
              isNew,
            },
          },
          "1",
          FlexLayout.DockLocation.CENTER,
          0,
          true
        )
      );
    } catch {
      this.state.model.doAction(
        FlexLayout.Actions.selectTab(userAccountSubscriptionId)
      );
    }
  };

  handleTabRename = (nodeId, name) => {
    this.state.model.doAction(
      FlexLayout.Actions.updateNodeAttributes(nodeId, { name: name })
    );
  };

  factory = (node) => {
    const componentId = node.getComponent();
    //const OrganizationSubscriptionId = node.getId();

    if (componentId === "OrganizationSubscriptions") {
      return (
        <OrganizationSubscriptions
          onClick={this.handleOrganizationSubscriptionSelect}
          allowEdit={this.props.allowEdit}
        />
      );
    } else if (componentId === "OrganizationSubscription") {
      const { organizationSubscriptionId, submitCallbackFunction, isNew } =
        node.getConfig();
      return (
        <OrganizationSubscription
          organizationSubscriptionId={organizationSubscriptionId}
          submitCallbackFunction={submitCallbackFunction}
          isNew={isNew}
          onRename={this.handleTabRename}
          allowEdit={this.props.allowEdit}
        />
      );
    } else if (componentId === "UserAccountSubscriptions") {
      return (
        <UserAccountSubscriptions
          onClick={this.handleUserAccountSubscriptionSelect}
          allowEdit={this.props.allowEdit}
        />
      );
    } else if (componentId === "UserAccountSubscription") {
      const { userAccountSubscriptionId, submitCallbackFunction, isNew } =
        node.getConfig();
      return (
        <UserAccountSubscription
          userAccountSubscriptionId={userAccountSubscriptionId}
          submitCallbackFunction={submitCallbackFunction}
          isNew={isNew}
          onRename={this.handleTabRename}
          allowEdit={this.props.allowEdit}
        />
      );
    } else if (componentId === "Placeholder") {
      return <div></div>;
    } else {
      return null;
    }
  };

  buildFlexLayout = () => {
    return (
      <div className="flex-container auth-container">
        <FlexLayout.Layout model={this.state.model} factory={this.factory} />
      </div>
    );
  };

  render() {
    return this.buildFlexLayout();
  }
}

export default withTranslation(["auth"])(SubscriptionAdmin);
