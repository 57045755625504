import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import Table from "../../../common/components/table/table";
import { withTranslation } from "react-i18next";

class TradingMarketsTable extends Component {
  columns = [
    { path: "name", label: this.props.t("Market") },
    {
      path: "halted",
      label: this.props.t("Halted"),
      content: (tradingMarket) => (
        <FontAwesome
          style={{ color: tradingMarket.halted ? "green" : "red" }}
          name={tradingMarket.halted ? "check" : "times"}
        />
      ),
    },
    {
      path: "allowPick",
      label: this.props.t("AllowPick"),
      content: (tradingMarket) => (
        <FontAwesome
          style={{ color: tradingMarket.allowPick ? "green" : "red" }}
          name={tradingMarket.allowPick ? "check" : "times"}
        />
      ),
    },
    {
      path: "allowAllOrNone",
      label: this.props.t("AllowAllOrNone"),
      content: (tradingMarket) => (
        <FontAwesome
          style={{ color: tradingMarket.allowAllOrNone ? "green" : "red" }}
          name={tradingMarket.allowAllOrNone ? "check" : "times"}
        />
      ),
    },
    {
      path: "allowFillOrKill",
      label: this.props.t("AllowFillOrKill"),
      content: (tradingMarket) => (
        <FontAwesome
          style={{ color: tradingMarket.allowFillOrKill ? "green" : "red" }}
          name={tradingMarket.allowFillOrKill ? "check" : "times"}
        />
      ),
    },
    {
      path: "withdrawOnCrossTrade",
      label: this.props.t("WithdrawOnCrossTrade"),
      content: (tradingMarket) => (
        <FontAwesome
          style={{
            color: tradingMarket.withdrawOnCrossTrade ? "green" : "red",
          }}
          name={tradingMarket.withdrawOnCrossTrade ? "check" : "times"}
        />
      ),
    },
    {
      path: "applyCounterpartyWhitelist",
      label: this.props.t("ApplyCounterpartyWhitelist"),
      content: (tradingMarket) => (
        <FontAwesome
          style={{
            color: tradingMarket.applyCounterpartyWhitelist ? "green" : "red",
          }}
          name={tradingMarket.applyCounterpartyWhitelist ? "check" : "times"}
        />
      ),
    },
    {
      path: "active",
      label: this.props.t("Active"),
      content: (tradingMarket) => (
        <FontAwesome
          style={{ color: tradingMarket.active ? "green" : "red" }}
          name={tradingMarket.active ? "check" : "times"}
        />
      ),
    },
  ];

  // constructor() {
  //     super();
  //     const user = auth.getCurrentUser();
  //     if (user && user.isAdmin)
  //         this.columns.push(this.deleteColumn);
  // }

  render() {
    const { tradingMarkets, onSort, sortColumn, onRowClick } = this.props;
    return (
      <Table
        className="table-bold"
        columns={this.columns}
        sortColumn={sortColumn}
        onSort={onSort}
        data={tradingMarkets}
        onRowClick={onRowClick}
        valueProperty="marketId"
      />
    );
  }
}

export default withTranslation(["trading"])(TradingMarketsTable);
