import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import Table from "../../../common/components/table/table";
import { withTranslation } from "react-i18next";

class UomTable extends Component {
  columns = [
    { path: "name", label: this.props.t("Name") },
    { path: "code", label: this.props.t("UomCode") },
    { path: "uomTypeName", label: this.props.t("UomType") },

    {
      path: "active",
      label: this.props.t("Active"),
      content: (uom) => <FontAwesome name={uom.active ? "check" : "times"} />,
    },
  ];

  // constructor() {
  //     super();
  //     const user = markets.getCurrentUser();
  //     if (user && user.isAdmin)
  //         this.columns.push(this.deleteColumn);
  // }

  render() {
    const { uoms, onSort, sortColumn, onRowClick } = this.props;
    return (
      <Table
        className="table-bold"
        columns={this.columns}
        sortColumn={sortColumn}
        onSort={onSort}
        data={uoms}
        onRowClick={onRowClick}
        valueProperty="uomId"
      />
    );
  }
}

export default withTranslation(["markets"])(UomTable);
