import React from "react";
import PropTypes from "prop-types";

/**
 * TableHeader component for rendering the header row of a table
 * Includes sorting functionality and accessibility features
 */
const TableHeader = ({
  sortColumn = { path: "name", order: "asc" }, // Default value to prevent null errors
  onSort,
  columns,
}) => {
  // Safely handle sort action with null check
  const handleSort = (path) => {
    if (!onSort) return;

    const newSortColumn = { ...sortColumn };
    if (newSortColumn.path === path) {
      newSortColumn.order = newSortColumn.order === "asc" ? "desc" : "asc";
    } else {
      newSortColumn.path = path;
      newSortColumn.order = "asc";
    }
    onSort(newSortColumn);
  };

  // Conditionally render sort icon based on current sort column
  const renderSortIcon = (column) => {
    // Safety check for column path
    if (!column.path || column.path !== sortColumn.path) return null;

    const iconClass =
      sortColumn.order === "asc" ? "fa fa-sort-asc" : "fa fa-sort-desc";

    return <i className={iconClass} aria-hidden="true" />;
  };

  return (
    <thead>
      <tr>
        {columns.map((column) => (
          <th
            className={`${column.className ?? ""} ${
              onSort && column.path ? "clickable" : ""
            }`}
            key={column.path || column.key}
            onClick={() => column.path && handleSort(column.path)}
            role={onSort && column.path ? "button" : undefined}
            tabIndex={onSort && column.path ? 0 : undefined}
          >
            {column.label} {renderSortIcon(column)}
          </th>
        ))}
      </tr>
    </thead>
  );
};

TableHeader.propTypes = {
  sortColumn: PropTypes.shape({
    path: PropTypes.string,
    order: PropTypes.oneOf(["asc", "desc"]),
  }),
  onSort: PropTypes.func,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      key: PropTypes.string,
      label: PropTypes.node,
      className: PropTypes.string,
    })
  ).isRequired,
};

export default TableHeader;
