import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const Pagination = ({
  itemsCount,
  pageSize,
  currentPage,
  onPageChange,
  disabled,
  maxPages = 5,
}) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);
  if (pagesCount <= 1) return null;

  // Calculate visible page range
  const getPageRange = () => {
    const halfWay = Math.floor(maxPages / 2);
    let start = Math.max(currentPage - halfWay, 1);
    let end = Math.min(start + maxPages - 1, pagesCount);

    // Adjust start if we're near the end
    if (end - start + 1 < maxPages) {
      start = Math.max(end - maxPages + 1, 1);
    }

    return _.range(start, end + 1);
  };

  const pages = getPageRange();

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination pagination-sm">
        {/* First page button */}
        {currentPage > 1 && (
          <li className="page-item">
            <button
              onClick={() => onPageChange(1)}
              className="page-link"
              disabled={disabled}
              aria-label="First page"
            >
              <i className="fa fa-angle-double-left" />
            </button>
          </li>
        )}

        {/* Page numbers */}
        {pages.map((page) => (
          <li
            key={page}
            className={`page-item ${page === currentPage ? "active" : ""}`}
          >
            <button
              onClick={() => onPageChange(page)}
              className="page-link"
              disabled={disabled}
              aria-current={page === currentPage ? "page" : undefined}
            >
              {page}
            </button>
          </li>
        ))}

        {/* Last page button */}
        {currentPage < pagesCount && (
          <li className="page-item">
            <button
              onClick={() => onPageChange(pagesCount)}
              className="page-link"
              disabled={disabled}
              aria-label="Last page"
            >
              <i className="fa fa-angle-double-right" />
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  maxPages: PropTypes.number,
};

export default Pagination;

/**
 * Helper function to paginate an array of items
 * Returns a slice of the array for the specified page
 * Safely handles null/undefined inputs by returning an empty array
 */
export const paginate = (items, pageNumber, pageSize) => {
  // Return empty array if items is not an array or is null/undefined
  if (!items || !Array.isArray(items)) return [];

  const startIndex = (pageNumber - 1) * pageSize;
  return _(items).slice(startIndex).take(pageSize).value();
};
