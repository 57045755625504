import React from "react";
import Joi from "joi-browser";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { Tabs, Tab } from "react-bootstrap";
import {
  getOrganizationSubscription,
  addOrganizationSubscription,
  updateOrganizationSubscription,
} from "../../../common/services/marketdata/organizationSubscriptionService";
import { getSubscribables } from "../../../common/services/marketdata/subscribableService";
import { getOrganizations } from "../../../common/services/auth/organizationService";
import Form from "../../../common/components/form/form";
import Loading from "../../../common/components/loading/loading";
import OrganizationSubscriptionHistory from "./organizationSubscriptionHistory";
import { withTranslation } from "react-i18next";

class OrganizationSubscription extends Form {
  state = {
    data: {
      organizationSubscriptionId: "",
      organizationId: [""],
      organizationName: "",
      subscribableId: "",
      subscribableName: "",
      compensated: "",
      active: "",
      eventId: "",
    },
    createdDate: "",
    updatedDate: "",
    organizations: [],
    subscribables: [],
    errors: {},
    isLoading: true,
    isNew: true,
  };

  schema = {
    organizationSubscriptionId: Joi.string(),
    subscribableId: Joi.string().required().label(this.props.t("Subscribable")),
    subscribableName: Joi.string().label(this.props.t("SubscribableName")),
    organizationId: Joi.array()
      .items(Joi.object())
      .min(1)
      .max(1)
      .required()
      .label(this.props.t("Organization")),
    organizationName: Joi.string().label(this.props.t("OrganizationName")),
    compensated: Joi.boolean().label(this.props.t("Compensated")),
    active: Joi.boolean().label(this.props.t("Active")),
    eventId: Joi.string(),
  };

  async componentDidMount() {
    //try{
    const { organizationSubscriptionId, isNew } = this.props;
    this.setState({ isNew: isNew });

    this.setState({
      subscribables: await getSubscribables(),
    });

    const organizations = await getOrganizations();

    var organizationSubscription = {};

    if (!isNew) {
      organizationSubscription = await getOrganizationSubscription(
        organizationSubscriptionId
      );
      organizationSubscription.eventId = uuid();
      organizationSubscription.organizationId = organizations.find(
        (x) => x.organizationId === organizationSubscription.organizationId
      );
    } //New Organization Subscribable Subscription Add
    else {
      organizationSubscription = {
        organizationSubscriptionId: organizationSubscriptionId,
        subscribableId: "",
        organizationId: "",
        compensated: false,
        active: true,
        eventId: uuid(),
      };
    }

    this.setState({
      data: this.mapToViewModel(organizationSubscription),
      isLoading: false,
    });

    this.setState({
      organizations: organizations.filter(
        (x) =>
          x.active ||
          x.organizationId === organizationSubscription.organizationId
      ),
    });

    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    this.setState({
      createdDate: new Date(
        organizationSubscription.createdDate
      ).toLocaleDateString("en-US", dateFormatOptions),
      updatedDate: new Date(
        organizationSubscription.updatedDate
      ).toLocaleDateString("en-US", dateFormatOptions),
    });

    // }
    // catch(ex)
    // {
    //     //Handle Erorr..
    // }
  }

  mapToViewModel(organizationSubscription) {
    return {
      organizationSubscriptionId:
        organizationSubscription.organizationSubscriptionId,
      subscribableId: organizationSubscription.subscribableId,
      organizationId: [organizationSubscription.organizationId],
      compensated: organizationSubscription.compensated,
      active: organizationSubscription.active,
      eventId: organizationSubscription.eventId,
    };
  }

  doSubmit = async () => {
    const { t, onRename } = this.props;
    const { isNew } = this.state;

    try {
      //Reset the event ID
      const data = { ...this.state.data };
      data.eventId = uuid();
      this.setState({ data: data });

      //Justn updating data after the setState is causing an asynch colision, this avoids that.
      var submitData = { ...data };
      submitData.organizationId = data.organizationId[0].organizationId; //force back to a single object from array before passing to API.
      let response = {};

      if (!isNew) {
        response = await updateOrganizationSubscription(submitData);

        toast.success(t("OrganizationSubscriptionUpdated"));
      } else {
        response = await addOrganizationSubscription(submitData);

        this.setState({ isNew: false });

        onRename(
          this.state.data.organizationSubscriptionId,
          response.organizationName + " | " + response.subscribableName
        );
        toast.success(t("OrganizationSubscriptionAdded"));
      }

      //Call back to the select list to allow it to update.
      this.props.submitCallbackFunction(response);
    } catch (ex) {
      console.log(t("ErrorSaving"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        if (errorList) {
          var errorKeys = Object.keys(ex.response.data.errors);
          errorKeys.forEach(function (key) {
            errorList[key].map((error) => {
              toast.error(t("ErrorSaving") + " " + key + ": " + error);
              return null;
            });
          });
        } else {
          toast.error(t("ErrorSaving") + " " + ex.response.data);
        }
        const errors = { ...this.state.errors };
        errors.Login = ex.response.data;
        toast.error(ex.response.data.errors.title);
        this.setState({ errors });
      }
    }
  };

  render() {
    const { isLoading, isNew, createdDate, updatedDate } = this.state;

    if (isLoading) return <Loading />;

    const { t, organizationSubscriptionId, allowEdit } = this.props;

    return (
      <div className="container-fluid">
        <form className="form-inline" onSubmit={this.handleSubmit}>
          <fieldset disabled={allowEdit ? "" : "disabled"}>
            <div>
              {this.renderSwitch("active", t("Active"))}
              {this.renderSwitch("compensated", t("Compensated"))}
              {isNew
                ? this.renderSelect(
                    "subscribableId",
                    t("Subscribable"),
                    this.state.subscribables,
                    "id"
                  )
                : this.renderDisabledSelect(
                    "subscribableId",
                    t("Subscribable"),
                    this.state.subscribables,
                    "id"
                  )}
              {this.renderTypeahead(
                "organizationId",
                t("Organization"),
                this.state.organizations,
                !isNew, //disabled if not new
                "organizationId"
              )}
              {allowEdit && this.renderButton(t("Save"))}
              <div className="form-footnote">
                {createdDate !== "Invalid Date" && (
                  <div>
                    {t("Created")} {createdDate}
                  </div>
                )}
                {updatedDate !== "Invalid Date" && (
                  <div>
                    {t("Updated")} {updatedDate}
                  </div>
                )}
              </div>
            </div>
          </fieldset>
        </form>
        {!isNew && (
          <div>
            <Tabs
              className="nav-dark nav-tabs-sm"
              defaultActiveKey={1}
              id="organizationsubscribablesubscription-tabs"
              mountOnEnter={true}
              unmountOnExit={true}
            >
              <Tab eventKey={1} title={t("History")}>
                <OrganizationSubscriptionHistory
                  organizationSubscriptionId={organizationSubscriptionId}
                />
              </Tab>
            </Tabs>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation(["auth"])(OrganizationSubscription);
