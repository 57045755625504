import React from "react";
import Joi from "joi-browser";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { Tabs, Tab } from "react-bootstrap";
import {
  getTerm,
  addTerm,
  updateTerm,
} from "../../../common/services/markets/termService";
import { getTermSchedules } from "../../../common/services/markets/termScheduleService";
import Form from "../../../common/components/form/form";
import Loading from "../../../common/components/loading/loading";
import TermHistory from "./termHistory";
import { withTranslation } from "react-i18next";
import { formatDate, formatDateTime } from "../../../common/services/utilities";

class Term extends Form {
  state = {
    data: {
      termId: "",
      name: "",
      termScheduleId: "",
      startDate: "",
      endDate: "",
      effectiveDate: "",
      expiryDate: "",
      active: "",
      eventId: "",
    },
    createdDate: "",
    updatedDate: "",
    termSchedules: [],
    errors: {},
    isLoading: true,
    isNew: true,
  };

  schema = {
    termId: Joi.string(),
    name: Joi.string().required().label(this.props.t("TermName")),
    termScheduleId: Joi.string().required().label(this.props.t("TermType")),
    startDate: Joi.date().required(),
    endDate: Joi.date().required(),
    effectiveDate: Joi.date().required(),
    expiryDate: Joi.date().required(),
    active: Joi.boolean().label(this.props.t("Active")),
    eventId: Joi.string(),
  };

  async componentDidMount() {
    //try{
    const { termId, isNew } = this.props;
    this.setState({ isNew: isNew });

    var term = {};

    if (!isNew) {
      term = await getTerm(termId);
      term.eventId = uuid();
    } //New User Add
    else {
      term = {
        termId: termId,
        name: "",
        termScheduleId: "",
        startDate: "",
        endDate: "",
        effectiveDate: "",
        expiryDate: "",
        active: true,
        eventId: uuid(),
        periodicity: "Day",
      };
    }

    this.setState({
      termSchedules: (await getTermSchedules()).filter(
        (x) => x.active || x.termScheduleId === term.termScheduleId
      ),
    });
    this.setState({ data: this.mapToViewModel(term), isLoading: false });

    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    this.setState({
      createdDate: new Date(term.createdDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      updatedDate: new Date(term.updatedDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
    });

    // }
    // catch(ex)
    // {
    //     //Handle Erorr..
    // }
  }

  async componentDidUpdate(_, prevState) {
    const { data, termSchedules } = this.state;
    const { termScheduleId } = data;

    if (termScheduleId !== prevState.data.termScheduleId) {
      const termSchedule = {
        ...termSchedules.find((x) => x.termScheduleId === termScheduleId),
      };
      if (termSchedule) {
        this.setState({
          periodicity: termSchedule.forwardReportingPeriodicity,
          data: this.mapToViewModel(data),
        });
      }
    }
  }

  mapToViewModel(term) {
    const { termSchedules } = this.state;
    const termSchedule = {
      ...termSchedules.find((x) => x.termScheduleId === term.termScheduleId),
    };
    const periodicity = termSchedule.forwardReportingPeriodicity;

    return {
      termId: term.termId,
      name: term.name,
      termScheduleId: term.termScheduleId,
      startDate:
        periodicity === "Hour"
          ? formatDateTime(term.startDate)
          : formatDate(term.startDate),
      endDate:
        periodicity === "Hour"
          ? formatDateTime(term.endDate)
          : formatDate(term.endDate),
      effectiveDate: formatDate(term.effectiveDate),
      expiryDate: formatDate(term.expiryDate),
      active: term.active,
      eventId: term.eventId,
    };
  }

  // async componentDidUpdate(_, prevState) {
  //   console.log(this.state.data);
  // }

  doSubmit = async () => {
    const { t, onRename } = this.props;
    const { isNew } = this.state;

    try {
      //Reset the event ID
      const data = { ...this.state.data };
      data.eventId = uuid();
      this.setState({ data: data });
      let response = {};

      if (!isNew) {
        response = await updateTerm(this.state.data);
        toast.success(t("TermUpdated"));
      } else {
        response = await addTerm(this.state.data);
        this.setState({ isNew: false });
        onRename(this.state.data.termId, this.state.data.name);
        toast.success(t("TermAdded"));
      }

      //Call back to the select list to allow it to update.
      this.props.submitCallbackFunction(response);
    } catch (ex) {
      console.log(t("ErrorSaving"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        if (errorList) {
          var errorKeys = Object.keys(ex.response.data.errors);
          errorKeys.forEach(function (key) {
            errorList[key].map((error) => {
              toast.error(t("ErrorSaving") + " " + key + ": " + error);
              return null;
            });
          });
        } else {
          toast.error(t("ErrorSaving") + " " + ex.response.data);
        }
        const errors = { ...this.state.errors };
        errors.Name = ex.response.data;
        toast.error(ex.response.data.errors.title);
        this.setState({ errors });
      }
    }
  };

  render() {
    const { isLoading, isNew, createdDate, updatedDate, periodicity } =
      this.state;

    if (isLoading) return <Loading />;

    const { t, termId, allowEdit } = this.props;

    return (
      <div className="container-fluid">
        <form className="form-inline" onSubmit={this.handleSubmit}>
          <fieldset disabled={allowEdit ? "" : "disabled"}>
            <div>
              {this.renderSwitch("active", t("Active"))}
              {this.renderInput("name", t("TermName"))}
              {this.renderSelect(
                "termScheduleId",
                t("TermSchedule"),
                this.state.termSchedules,
                "termScheduleId"
              )}
              {periodicity === "Hour" &&
                this.renderDateTimeInput(
                  "startDate",
                  t("StartDate"),
                  null,
                  null
                )}
              {periodicity !== "Hour" &&
                this.renderDateInput("startDate", t("StartDate"), null, null)}
              {periodicity === "Hour" &&
                this.renderDateTimeInput("endDate", t("EndDate"), null, null)}
              {periodicity !== "Hour" &&
                this.renderDateInput("endDate", t("EndDate"), null, null)}
              {this.renderDateInput(
                "effectiveDate",
                t("EffectiveDate"),
                null,
                null
              )}
              {this.renderDateInput("expiryDate", t("ExpiryDate"), null, null)}
              {allowEdit && this.renderButton(t("Save"))}
              <div className="form-footnote">
                {createdDate !== "Invalid Date" && (
                  <div>
                    {t("Created")} {createdDate}
                  </div>
                )}
                {updatedDate !== "Invalid Date" && (
                  <div>
                    {t("Updated")} {updatedDate}
                  </div>
                )}
              </div>
            </div>
          </fieldset>
        </form>
        {!isNew && (
          <div>
            <Tabs
              className="nav-dark nav-tabs-sm"
              defaultActiveKey={1}
              id="term-tabs"
              mountOnEnter={true}
              unmountOnExit={true}
            >
              <Tab eventKey={1} title={t("History")}>
                <TermHistory termId={termId} />
              </Tab>
            </Tabs>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation(["markets"])(Term);
