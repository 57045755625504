import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import MenuItemWithTooltip from "./MenuItemWithTooltip";

const MenuSection = ({
  title,
  icon,
  children,
  isExpanded,
  onToggle,
  menuId,
  parentIds = [],
  sidebarExpanded,
  onCollapseOthers,
  activeMenuItems,
  enableHoverBehavior = true,
  isSubmenuItem = false,
}) => {
  const location = useLocation();
  const [hasActiveChild, setHasActiveChild] = useState(false);
  const [isDrawerHovered, setIsDrawerHovered] = useState(false);
  const [hasActiveSubmenuItem, setHasActiveSubmenuItem] = useState(false);
  const menuRef = useRef(null);
  const triggerRef = useRef(null);
  const hoverTimeoutRef = useRef(null);
  const leaveTimeoutRef = useRef(null);
  const isOverTriggerRef = useRef(false);
  const isOverMenuRef = useRef(false);
  const isProcessingEventRef = useRef(false);
  const drawerSwitchingRef = useRef(false);
  const positionUpdateIntervalRef = useRef(null);
  const resizeObserverRef = useRef(null);
  // Add a ref to track if drawer is currently closing to prevent flashing
  const isClosingDrawerRef = useRef(false);

  // Only keep refs that are actually used
  const mousePositionRef = useRef({ x: 0, y: 0 });

  // More responsive drawer closing logic
  const closeDrawer = useCallback(() => {
    // Prevent multiple close attempts in quick succession
    if (isClosingDrawerRef.current) return;

    // Set closing flag
    isClosingDrawerRef.current = true;

    // Update hover state
    if (!sidebarExpanded) {
      setIsDrawerHovered(false);
    }

    // Ensure the positioning class is removed
    document.body.classList.remove("positioning-drawer");

    // Clear any pending hover timeouts to prevent reopening
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }

    // Clear any pending leave timeouts
    if (leaveTimeoutRef.current) {
      clearTimeout(leaveTimeoutRef.current);
      leaveTimeoutRef.current = null;
    }

    // Close the drawer with a mouseleave event
    onToggle({ type: "mouseleave", menuId: menuId });

    // Reset closing flag after a short delay
    setTimeout(() => {
      isClosingDrawerRef.current = false;
    }, 100);
  }, [sidebarExpanded, onToggle, menuId]);

  useEffect(() => {
    // Check if any child items match the current route
    const checkForActiveChildren = () => {
      return activeMenuItems?.has(menuId) || false;
    };

    setHasActiveChild(checkForActiveChildren());

    // Also check if any submenu items are active based on the current route
    // This ensures the parent trigger is highlighted when a submenu item is active
    const hasActiveSubmenu = React.Children.toArray(children).some((child) => {
      if (React.isValidElement(child) && child.props.to) {
        return (
          activeMenuItems?.has(child.props.to) ||
          location.pathname === child.props.to
        );
      }
      return false;
    });

    setHasActiveSubmenuItem(hasActiveSubmenu);
  }, [location.pathname, menuId, activeMenuItems, children]);

  // Handle selection of a submenu item
  const handleSubmenuItemSelection = (item) => {
    if (item.isActive) {
      setHasActiveSubmenuItem(true);

      // Ensure the parent menu stays expanded when a submenu item is selected
      if (onToggle && !isExpanded && !sidebarExpanded) {
        onToggle({ type: "submenuItemSelected", menuId: menuId });
      }
    }
  };

  // Reset active submenu item state when route changes
  useEffect(() => {
    setHasActiveSubmenuItem(false);
  }, [location.pathname]);

  // Position the drawer function - extracted for reuse
  const positionDrawer = useCallback(() => {
    if (
      !sidebarExpanded &&
      isExpanded &&
      triggerRef.current &&
      menuRef.current
    ) {
      // We'll no longer disable transitions during positioning to avoid flicker
      // Instead, we'll use transform for positioning, which is more efficient

      const submenu = menuRef.current.querySelector(".submenu");
      if (submenu) {
        // Add a class to prevent scrollbar during positioning
        document.body.classList.add("positioning-drawer");

        const triggerRect = triggerRef.current.getBoundingClientRect();

        // Position the submenu at the same top position as the trigger
        submenu.style.top = `${triggerRect.top}px`;

        // Ensure the drawer doesn't go below the viewport
        const viewportHeight = window.innerHeight;
        const submenuHeight = submenu.scrollHeight;

        // Be more careful about repositioning to avoid jumps
        if (triggerRect.top + submenuHeight > viewportHeight - 20) {
          // If the drawer would go below the viewport, align it to the bottom
          // with a small space from bottom of viewport
          const bottomSpace = 20;
          const newTopPosition = Math.max(
            0,
            viewportHeight - submenuHeight - bottomSpace
          );
          submenu.style.top = `${newTopPosition}px`;
        }

        // Remove the class after positioning
        setTimeout(() => {
          document.body.classList.remove("positioning-drawer");
        }, 10);
      }
    }
  }, [sidebarExpanded, isExpanded]);

  // Position the submenu drawer next to its trigger when expanded
  useEffect(() => {
    // Clear any existing interval
    if (positionUpdateIntervalRef.current) {
      clearInterval(positionUpdateIntervalRef.current);
      positionUpdateIntervalRef.current = null;
    }

    // Make sure to remove any positioning class that might be lingering
    document.body.classList.remove("positioning-drawer");

    if (!sidebarExpanded && isExpanded) {
      // Queue multiple positioning calls with increasing delays for smoother positioning
      setTimeout(positionDrawer, 0); // Immediate
      setTimeout(positionDrawer, 50); // Short delay
      setTimeout(positionDrawer, 150); // Medium delay
      setTimeout(positionDrawer, 300); // Longer delay for final adjustment

      // Set up continuous position updates for smoother experience
      // But use a longer interval to avoid constant repositioning
      positionUpdateIntervalRef.current = setInterval(positionDrawer, 500);

      // Set up resize observer to handle window resizing
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }

      resizeObserverRef.current = new ResizeObserver(() => {
        // Don't position immediately, use a slight delay to avoid flicker
        setTimeout(positionDrawer, 10);
      });

      if (triggerRef.current) {
        resizeObserverRef.current.observe(triggerRef.current);
      }

      if (menuRef.current) {
        resizeObserverRef.current.observe(menuRef.current);
      }

      // Also observe the document body for any layout changes
      resizeObserverRef.current.observe(document.body);
    }

    return () => {
      // Clean up interval and observer
      if (positionUpdateIntervalRef.current) {
        clearInterval(positionUpdateIntervalRef.current);
        positionUpdateIntervalRef.current = null;
      }

      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
        resizeObserverRef.current = null;
      }

      // Also make sure to remove the positioning class
      document.body.classList.remove("positioning-drawer");
    };
  }, [sidebarExpanded, isExpanded, positionDrawer]);

  // Handle clicks outside the drawer to close it
  useEffect(() => {
    if (!sidebarExpanded && isExpanded) {
      const handleClickOutside = (event) => {
        if (
          menuRef.current &&
          !menuRef.current.contains(event.target) &&
          triggerRef.current &&
          !triggerRef.current.contains(event.target)
        ) {
          // Close the menu if click is outside
          onToggle({ type: "click", isClickOutside: true });
        }
      };

      // Add click listener to document to catch all clicks
      document.addEventListener("mousedown", handleClickOutside);

      // Cleanup
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [sidebarExpanded, isExpanded, onToggle]);

  // Reset drawer switching state when drawer expands or collapses
  useEffect(() => {
    drawerSwitchingRef.current = false;

    // If drawer just expanded, ensure it's positioned correctly
    if (isExpanded && !sidebarExpanded) {
      // Position immediately
      positionDrawer();

      // And again after a short delay to ensure everything is rendered
      setTimeout(positionDrawer, 50);
    }
  }, [isExpanded, sidebarExpanded, positionDrawer]);

  // Enhanced real-time mouse position tracking
  useEffect(() => {
    // Only apply in collapsed mode with hover behavior enabled
    if (!sidebarExpanded && enableHoverBehavior) {
      const trackMousePosition = (e) => {
        // Skip if we're currently closing the drawer
        if (isClosingDrawerRef.current) return;

        // Update current mouse position in real time
        mousePositionRef.current = { x: e.clientX, y: e.clientY };

        // Skip if we're already processing an event
        if (isProcessingEventRef.current) return;

        // If the drawer is open, we want to check if mouse is over trigger or drawer
        if (isExpanded) {
          const elementsUnderCursor = document.elementsFromPoint(
            e.clientX,
            e.clientY
          );

          // Check if we're over trigger or submenu
          const isOverTrigger = elementsUnderCursor.some(
            (el) =>
              el === triggerRef.current || triggerRef.current?.contains(el)
          );

          const isOverSubmenu = elementsUnderCursor.some(
            (el) =>
              menuRef.current &&
              (el === menuRef.current || menuRef.current.contains(el))
          );

          // Update refs based on what we find
          isOverTriggerRef.current = isOverTrigger;
          isOverMenuRef.current = isOverSubmenu;

          // If we're not over either element and not currently closing
          if (!isOverTrigger && !isOverSubmenu && !isClosingDrawerRef.current) {
            // If we have a pending leave timeout, we don't need to make a new one
            if (!leaveTimeoutRef.current) {
              // Start a short timeout to verify we're really not over anything
              leaveTimeoutRef.current = setTimeout(() => {
                // Skip if closing is already in progress
                if (isClosingDrawerRef.current) {
                  leaveTimeoutRef.current = null;
                  return;
                }

                // Update elements under cursor one more time
                const currentElements = document.elementsFromPoint(
                  mousePositionRef.current.x,
                  mousePositionRef.current.y
                );

                const stillOverTrigger = currentElements.some(
                  (el) =>
                    el === triggerRef.current ||
                    triggerRef.current?.contains(el)
                );

                const stillOverSubmenu = currentElements.some(
                  (el) =>
                    menuRef.current &&
                    (el === menuRef.current || menuRef.current.contains(el))
                );

                // If we're still not over either element, close the drawer
                if (!stillOverTrigger && !stillOverSubmenu) {
                  closeDrawer();
                }

                leaveTimeoutRef.current = null;
              }, 30);
            }
          }
        }
      };

      // Add mousemove listener to document
      document.addEventListener("mousemove", trackMousePosition);

      // Set up a regular interval to periodically check position (as a safety check)
      const verifyInterval = setInterval(() => {
        // Skip verification if we're in the process of closing
        if (isClosingDrawerRef.current) return;

        // Only run verification if drawer is expanded
        if (isExpanded) {
          // Skip if we're not in hover mode or sidebar is expanded
          if (sidebarExpanded || !enableHoverBehavior) return;

          // Skip if we're processing an event
          if (isProcessingEventRef.current) return;

          // Get current elements under cursor
          const currentElements = document.elementsFromPoint(
            mousePositionRef.current.x,
            mousePositionRef.current.y
          );

          // Check if we're over trigger or submenu
          const isOverTrigger = currentElements.some(
            (el) =>
              el === triggerRef.current || triggerRef.current?.contains(el)
          );

          const isOverSubmenu = currentElements.some(
            (el) =>
              menuRef.current &&
              (el === menuRef.current || menuRef.current.contains(el))
          );

          // Update refs based on what we find
          const wasOverTrigger = isOverTriggerRef.current;
          const wasOverMenu = isOverMenuRef.current;
          isOverTriggerRef.current = isOverTrigger;
          isOverMenuRef.current = isOverSubmenu;

          // If we're no longer over either element, close the drawer immediately
          if (
            !isOverTrigger &&
            !isOverSubmenu &&
            (wasOverTrigger || wasOverMenu)
          ) {
            closeDrawer();
          }
        }
      }, 200);

      // Cleanup
      return () => {
        document.removeEventListener("mousemove", trackMousePosition);
        clearInterval(verifyInterval);
      };
    }
  }, [sidebarExpanded, enableHoverBehavior, isExpanded, closeDrawer]);

  // Enhanced mouse enter handler for more responsive opening
  const handleTriggerMouseEnter = useCallback(
    (e) => {
      // Prevent event bubbling to parent menu sections
      e.stopPropagation();

      // Set flag to indicate we're over the trigger
      isOverTriggerRef.current = true;

      // Clear any pending leave timeout
      if (leaveTimeoutRef.current) {
        clearTimeout(leaveTimeoutRef.current);
        leaveTimeoutRef.current = null;
      }

      // Also make sure hover timeout is cleared
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }

      // Detect if we're switching between menu items or doing first hover
      const isMenuSwitch =
        !sidebarExpanded &&
        document.querySelector(".menu-section.has-expanded-drawer") !== null;

      // Set a minimal timeout for opening to allow for quick mouse movements
      // Open immediately when switching between menus
      hoverTimeoutRef.current = setTimeout(
        () => {
          if (isOverTriggerRef.current) {
            // Pass a hover event object to indicate this is a hover
            onToggle({
              type: "hover",
              menuId: menuId,
              isMenuSwitch: isMenuSwitch,
              isDrawerSwitching: isMenuSwitch,
            });

            // Position the drawer immediately after opening
            requestAnimationFrame(() => {
              positionDrawer();

              // And again after a brief delay to ensure everything is rendered
              setTimeout(positionDrawer, 30);
            });
          }
        },
        isMenuSwitch ? 0 : 30
      ); // Use 0ms for immediate switching, 30ms for first hover
    },
    [sidebarExpanded, menuId, onToggle, positionDrawer]
  );

  // Fix handleTriggerMouseLeave
  const handleTriggerMouseLeave = useCallback(
    (e) => {
      // Skip if we're currently closing
      if (isClosingDrawerRef.current) return;

      // Prevent event bubbling to parent menu sections
      e.stopPropagation();

      // Set flag to indicate we're not over the trigger
      isOverTriggerRef.current = false;

      // Get real-time elements under the mouse cursor
      const elementsUnderCursor = document.elementsFromPoint(
        e.clientX,
        e.clientY
      );

      // Check if we're actually over another menu trigger
      const isOverAnotherTrigger = elementsUnderCursor.some((el) => {
        return (
          el.classList &&
          (el.classList.contains("menu-trigger") ||
            el.classList.contains("collapsed-trigger")) &&
          el !== triggerRef.current
        );
      });

      // If we're over another trigger, let that trigger's mouseenter handle things
      if (isOverAnotherTrigger) {
        setIsDrawerHovered(false);
        return;
      }

      // Check if we're over the submenu
      const isOverSubmenu = elementsUnderCursor.some((el) => {
        return (
          menuRef.current &&
          (el === menuRef.current || menuRef.current.contains(el))
        );
      });

      // Update submenu hover state
      isOverMenuRef.current = isOverSubmenu;

      // If not over the submenu, start a short timeout to close
      if (!isOverSubmenu) {
        leaveTimeoutRef.current = setTimeout(() => {
          // Skip if we're already closing
          if (isClosingDrawerRef.current) {
            leaveTimeoutRef.current = null;
            return;
          }

          // Double-check position before closing
          const currentElements = document.elementsFromPoint(
            mousePositionRef.current.x,
            mousePositionRef.current.y
          );

          // Only close if we're not over either element
          if (
            !currentElements.some(
              (el) =>
                el === triggerRef.current ||
                triggerRef.current?.contains(el) ||
                (menuRef.current &&
                  (el === menuRef.current || menuRef.current.contains(el)))
            )
          ) {
            closeDrawer();
          }

          leaveTimeoutRef.current = null;
        }, 20); // Quick response but prevents accidental closing
      }
    },
    [setIsDrawerHovered, closeDrawer]
  );

  const handleMenuMouseEnter = useCallback(
    (e) => {
      // Skip if we're currently closing
      if (isClosingDrawerRef.current) return;

      e.stopPropagation();
      isOverMenuRef.current = true;
      setIsDrawerHovered(true);

      // Clear any pending leave timeout
      if (leaveTimeoutRef.current) {
        clearTimeout(leaveTimeoutRef.current);
        leaveTimeoutRef.current = null;
      }
    },
    [] // setIsDrawerHovered is stable and doesn't need to be in dependencies
  );

  const handleMenuMouseLeave = useCallback(
    (e) => {
      // Skip if we're currently closing
      if (isClosingDrawerRef.current) return;

      e.stopPropagation();

      // Set flag to indicate we're not over the menu
      isOverMenuRef.current = false;

      // Get real-time elements under cursor
      const elementsUnderCursor = document.elementsFromPoint(
        e.clientX,
        e.clientY
      );

      // Check if we're actually over the trigger element
      const isOverTrigger = elementsUnderCursor.some((el) => {
        return el === triggerRef.current || triggerRef.current?.contains(el);
      });

      // Update trigger hover state
      isOverTriggerRef.current = isOverTrigger;

      // If not over the trigger, start a timeout to close the drawer
      if (!isOverTrigger) {
        leaveTimeoutRef.current = setTimeout(() => {
          // Skip if we're already closing
          if (isClosingDrawerRef.current) {
            leaveTimeoutRef.current = null;
            return;
          }

          // Double-check position before closing
          const currentElements = document.elementsFromPoint(
            mousePositionRef.current.x,
            mousePositionRef.current.y
          );

          const stillOverTrigger = currentElements.some(
            (el) =>
              el === triggerRef.current || triggerRef.current?.contains(el)
          );

          const stillOverSubmenu = currentElements.some(
            (el) =>
              menuRef.current &&
              (el === menuRef.current || menuRef.current.contains(el))
          );

          // If we're not over either element, close the drawer
          if (!stillOverTrigger && !stillOverSubmenu) {
            closeDrawer();
          }

          leaveTimeoutRef.current = null;
        }, 20); // Quick response but prevents accidental closing
      }
    },
    [closeDrawer]
  );

  // Add periodic position verification to ensure correct state, but less frequently
  useEffect(() => {
    // Only run in collapsed mode with hover behavior when the drawer is open
    if (!sidebarExpanded && enableHoverBehavior && isExpanded) {
      const verifyPosition = () => {
        // Skip if we're currently closing
        if (isClosingDrawerRef.current) return;

        // Get current elements under cursor
        const elementsUnderCursor = document.elementsFromPoint(
          mousePositionRef.current.x,
          mousePositionRef.current.y
        );

        // Check if we're over trigger or submenu
        const isOverTrigger = elementsUnderCursor.some(
          (el) => el === triggerRef.current || triggerRef.current?.contains(el)
        );

        const isOverSubmenu = elementsUnderCursor.some(
          (el) => el === menuRef.current || menuRef.current?.contains(el)
        );

        // Update drawer hovered state conservatively
        if (isOverTrigger || isOverSubmenu) {
          setIsDrawerHovered(true);
          isOverTriggerRef.current = isOverTrigger;
          isOverMenuRef.current = isOverSubmenu;
        }
        // Only close if we're absolutely sure we're not over either
        else if (
          !isDrawerHovered &&
          !isOverTriggerRef.current &&
          !isOverMenuRef.current
        ) {
          // Only update these refs if we're sure neither is hovered
          isOverTriggerRef.current = false;
          isOverMenuRef.current = false;
          setIsDrawerHovered(false);
          closeDrawer();
        }
      };

      // Verify less frequently to reduce the chance of interference with user interaction
      const intervalId = setInterval(verifyPosition, 400);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [
    sidebarExpanded,
    enableHoverBehavior,
    isExpanded,
    menuId,
    isDrawerHovered,
    closeDrawer,
  ]);

  // Ensure the trigger has a data-menu-trigger attribute for targeting
  useEffect(() => {
    if (triggerRef.current) {
      triggerRef.current.setAttribute("data-menu-trigger", menuId);
    }
  }, [menuId]);

  // Enhance handling for nested toggle within collapsed sidebar drawer
  const handleToggle = (e) => {
    // Prevent the toggle action from removing highlights
    if (e) e.stopPropagation();

    // Call the original toggle function
    if (onToggle) {
      // If this is a submenu trigger within a drawer in collapsed mode
      if (!sidebarExpanded && isSubmenuItem) {
        // Use a specific event type for nested submenu toggles
        onToggle({
          type: "nestedSubmenuToggle",
          menuId: menuId,
          isWithinDrawer: true,
        });
      } else {
        onToggle(e);

        // If this is a click to open the drawer, position it after a short delay
        if (!isExpanded && !sidebarExpanded) {
          setTimeout(positionDrawer, 10);
        }
      }
    }
  };

  // Add event listeners for mouse enter and leave
  useEffect(() => {
    if (!sidebarExpanded && enableHoverBehavior) {
      const triggerElement = triggerRef.current;
      const menuElement = menuRef.current;

      // Add event listeners
      if (triggerElement) {
        triggerElement.addEventListener("mouseenter", handleTriggerMouseEnter);
        triggerElement.addEventListener("mouseleave", handleTriggerMouseLeave);
      }

      if (menuElement) {
        menuElement.addEventListener("mouseenter", handleMenuMouseEnter);
        menuElement.addEventListener("mouseleave", handleMenuMouseLeave);
      }

      return () => {
        // Remove event listeners
        if (triggerElement) {
          triggerElement.removeEventListener(
            "mouseenter",
            handleTriggerMouseEnter
          );
          triggerElement.removeEventListener(
            "mouseleave",
            handleTriggerMouseLeave
          );
        }

        if (menuElement) {
          menuElement.removeEventListener("mouseenter", handleMenuMouseEnter);
          menuElement.removeEventListener("mouseleave", handleMenuMouseLeave);
        }
      };
    }
  }, [
    sidebarExpanded,
    enableHoverBehavior,
    handleTriggerMouseEnter,
    handleTriggerMouseLeave,
    handleMenuMouseEnter,
    handleMenuMouseLeave,
  ]);

  // More responsive drawer closing logic
  useEffect(() => {
    // More responsive drawer closing logic from document clicks
    if (!sidebarExpanded && enableHoverBehavior) {
      const documentClickHandler = (e) => {
        // Skip if we're over either element
        if (
          (triggerRef.current &&
            (e.target === triggerRef.current ||
              triggerRef.current.contains(e.target))) ||
          (menuRef.current &&
            (e.target === menuRef.current ||
              menuRef.current.contains(e.target)))
        ) {
          return;
        }

        // Only close when drawer is actually open
        if (isExpanded) {
          closeDrawer();
        }
      };

      document.addEventListener("mousedown", documentClickHandler);

      return () => {
        document.removeEventListener("mousedown", documentClickHandler);
      };
    }
  }, [sidebarExpanded, enableHoverBehavior, isExpanded, closeDrawer]);

  // Clean up when component unmounts
  useEffect(() => {
    return () => {
      // Remove positioning class from body if it exists
      document.body.classList.remove("positioning-drawer");

      // Clear any existing timeouts or intervals
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
      if (leaveTimeoutRef.current) {
        clearTimeout(leaveTimeoutRef.current);
      }
      if (positionUpdateIntervalRef.current) {
        clearInterval(positionUpdateIntervalRef.current);
      }
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, []);

  return (
    <div
      className={`menu-section ${isExpanded ? "has-expanded-drawer" : ""}`}
      data-menu-id={menuId}
      ref={menuRef}
    >
      <div
        ref={triggerRef}
        className="menu-trigger-wrapper"
        data-parent-ids={parentIds.join(",")}
        onMouseEnter={
          !sidebarExpanded && enableHoverBehavior && !isSubmenuItem
            ? (e) => {
                // Skip if we're currently closing
                if (isClosingDrawerRef.current) return;

                // Skip if drawer is already expanded
                if (isExpanded) {
                  // Just update the hover state
                  setIsDrawerHovered(true);
                  isOverTriggerRef.current = true;
                  return;
                }

                // Directly handle the hover with a small built-in delay
                if (!hoverTimeoutRef.current) {
                  hoverTimeoutRef.current = setTimeout(() => {
                    // Skip if we started closing during the timeout
                    if (isClosingDrawerRef.current) {
                      hoverTimeoutRef.current = null;
                      return;
                    }

                    onToggle({
                      type: "hover",
                      menuId: menuId,
                    });

                    // Position the drawer after it's opened
                    setTimeout(positionDrawer, 10);

                    hoverTimeoutRef.current = null;
                  }, 20);
                }
              }
            : undefined
        }
      >
        <MenuItemWithTooltip
          icon={icon}
          text={title}
          onClick={handleToggle}
          isButton={true}
          hasSubmenu={true}
          isExpanded={isExpanded}
          className={`${hasActiveChild ? "has-active-child" : ""} ${
            !sidebarExpanded ? "collapsed-trigger" : ""
          } ${
            !sidebarExpanded && (isDrawerHovered || hasActiveSubmenuItem)
              ? "drawer-hovered"
              : ""
          }`}
          parentIds={parentIds}
          onCollapseOthers={onCollapseOthers}
          activeMenuItems={activeMenuItems}
        />
      </div>
      <div
        className={`submenu ${isExpanded ? "expanded" : ""} ${
          !sidebarExpanded && isSubmenuItem ? "nested-submenu" : ""
        }`}
      >
        {/* Add drawer header with parent title when expanded and not in sidebar expanded mode */}
        {isExpanded && !sidebarExpanded && !isSubmenuItem && (
          <div className="drawer-header">
            <span className="drawer-header-title">{title}</span>
          </div>
        )}
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            if (child.type === MenuSection) {
              return React.cloneElement(child, {
                parentIds: [...parentIds, menuId].filter(Boolean),
                sidebarExpanded: sidebarExpanded,
                onCollapseOthers: onCollapseOthers,
                activeMenuItems: activeMenuItems,
                enableHoverBehavior: enableHoverBehavior,
                isSubmenuItem: true, // Mark as submenu item for proper nesting
              });
            } else {
              return React.cloneElement(child, {
                parentIds: [...parentIds, menuId].filter(Boolean),
                parentId: menuId,
                sidebarExpanded: sidebarExpanded,
                onCollapseOthers: onCollapseOthers,
                activeMenuItems: activeMenuItems,
                onSelectItem: handleSubmenuItemSelection,
              });
            }
          }
          return child;
        })}
      </div>
    </div>
  );
};

export default MenuSection;
