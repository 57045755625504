import React from "react";
import Joi from "joi-browser";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { Tabs, Tab } from "react-bootstrap";
import {
  getProduct,
  addProduct,
  updateProduct,
} from "../../../common/services/products/productService";
import { getCommodities } from "../../../common/services/products/commodityService";
import { getAliases } from "../../../common/services/products/aliasService";
import Form from "../../../common/components/form/form";
import Loading from "../../../common/components/loading/loading";
import ProductHistory from "./productHistory";
import ProductAliases from "./productAliases";
import ProductAttributes from "./productQualityAttributes";
import { withTranslation } from "react-i18next";
import ProductAttributePriceDrivers from "./productQualityAttributePriceDrivers";

class Product extends Form {
  state = {
    data: {
      productId: "",
      name: "",
      shortName: "",
      active: "",
      eventId: "",
    },
    createdDate: "",
    updatedDate: "",
    commodities: [],
    aliases: [],
    errors: {},
    isLoading: true,
    isNew: true,
  };

  schema = {
    productId: Joi.string(),
    name: Joi.string().required().label(this.props.t("ProductName")),
    shortName: Joi.string().label(this.props.t("ProductShortName")),
    commodityId: Joi.string().required().label(this.props.t("Commodity")),
    active: Joi.boolean().label(this.props.t("Active")),
    eventId: Joi.string(),
  };

  async componentDidMount() {
    //try{
    const { productId, isNew } = this.props;
    this.setState({ isNew: isNew });
    var product = {};

    if (!isNew) {
      product = await getProduct(productId);
      product.eventId = uuid();
    } //New User Add
    else {
      product = {
        productId: productId,
        name: "",
        shortName: "",
        commodityId: "",
        active: true,
        eventId: uuid(),
      };
    }
    this.setState({ data: this.mapToViewModel(product), isLoading: false });

    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    this.setState({
      createdDate: new Date(product.createdDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      updatedDate: new Date(product.updatedDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
    });

    this.setState({
      commodities: (await getCommodities()).filter(
        (x) => x.active || x.commodityId === product.commodityId
      ),
    });
    this.setState({ aliases: await getAliases() });

    // }
    // catch(ex)
    // {
    //     //Handle Erorr..
    // }
  }

  mapToViewModel(product) {
    return {
      productId: product.productId,
      name: product.name,
      shortName: product.shortName,
      commodityId: product.commodityId,
      active: product.active,
      eventId: product.eventId,
    };
  }

  doSubmit = async () => {
    const { t, onRename } = this.props;
    const { isNew } = this.state;

    try {
      //Reset the event ID
      const data = { ...this.state.data };
      data.eventId = uuid();
      this.setState({ data: data });

      if (!isNew) {
        await updateProduct(this.state.data);
        toast.success(t("ProductUpdated"));
      } else {
        await addProduct(this.state.data);
        this.setState({ isNew: false });
        onRename(this.state.data.productId, this.state.data.name);
        toast.success(t("ProductAdded"));
      }

      //Call back to the select list to allow it to update.
      this.props.submitCallbackFunction(this.state.data);
    } catch (ex) {
      console.log(t("ErrorSaving"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        if (errorList) {
          var errorKeys = Object.keys(ex.response.data.errors);
          errorKeys.forEach(function (key) {
            errorList[key].map((error) => {
              toast.error(t("ErrorSaving") + " " + key + ": " + error);
              return null;
            });
          });
        } else {
          toast.error(t("ErrorSaving") + " " + ex.response.data);
        }
        const errors = { ...this.state.errors };
        errors.Name = ex.response.data;
        toast.error(ex.response.data.errors.title);
        this.setState({ errors });
      }
    }
  };

  render() {
    const { isLoading, isNew, createdDate, updatedDate } = this.state;

    if (isLoading) return <Loading />;

    const { t, productId, allowEdit } = this.props;

    return (
      <div className="container-fluid">
        <form className="form-inline" onSubmit={this.handleSubmit}>
          <fieldset disabled={allowEdit ? "" : "disabled"}>
            <div>
              {this.renderSwitch("active", t("Active"))}
              {this.renderInput("name", t("ProductName"))}
              {this.renderInput("shortName", t("ProductShortName"))}
              {this.renderSelect(
                "commodityId",
                t("Commodity"),
                this.state.commodities,
                "commodityId"
              )}
              {allowEdit && this.renderButton(t("Save"))}
              <div className="form-footnote">
                {createdDate !== "Invalid Date" && (
                  <div>
                    {t("Created")} {createdDate}
                  </div>
                )}
                {updatedDate !== "Invalid Date" && (
                  <div>
                    {t("Updated")} {updatedDate}
                  </div>
                )}
              </div>
            </div>
          </fieldset>
        </form>
        {!isNew && (
          <div>
            <Tabs
              className="nav-dark nav-tabs-sm"
              defaultActiveKey={1}
              id="product-tabs"
              mountOnEnter={true}
              unmountOnExit={true}
            >
              <Tab eventKey={1} title={t("Attributes")}>
                <ProductAttributes
                  productId={productId}
                  allowEdit={allowEdit}
                />
              </Tab>
              <Tab eventKey={2} title={t("Price Drivers")}>
                <ProductAttributePriceDrivers
                  productId={productId}
                  allowEdit={allowEdit}
                />
              </Tab>
              <Tab eventKey={3} title={t("Aliases")}>
                <ProductAliases productId={productId} />
              </Tab>
              <Tab eventKey={4} title={t("History")}>
                <ProductHistory productId={productId} />
              </Tab>
            </Tabs>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation(["products"])(Product);
