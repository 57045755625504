import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import Table from "../../../common/components/table/table";
import { withTranslation } from "react-i18next";

class UserAccountSubscriptionsTable extends Component {
  columns = [
    {
      path: "login",
      label: this.props.t("UserAccountName"),
    },
    {
      path: "organizationSubscriptionName",
      label: this.props.t("SubscriptionName"),
    },
    {
      path: "contributor",
      label: this.props.t("Contributor"),
      content: (userAccountSubscription) => (
        <FontAwesome
          name={userAccountSubscription.contributor ? "check" : "times"}
        />
      ),
    },
    {
      path: "active",
      label: this.props.t("Active"),
      content: (userAccountSubscription) => (
        <FontAwesome
          name={userAccountSubscription.active ? "check" : "times"}
        />
      ),
    },
  ];

  // constructor() {
  //     super();
  //     const user = auth.getCurrentUser();
  //     if (user && user.isAdmin)
  //         this.columns.push(this.deleteColumn);
  // }

  render() {
    const { userAccountSubscriptions, onSort, sortColumn, onRowClick } =
      this.props;
    return (
      <Table
        className="table-bold"
        columns={this.columns}
        sortColumn={sortColumn}
        onSort={onSort}
        data={userAccountSubscriptions}
        onRowClick={onRowClick}
        valueProperty="userAccountSubscriptionId"
      />
    );
  }
}

export default withTranslation(["auth"])(UserAccountSubscriptionsTable);
