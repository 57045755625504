import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const MenuStateManager = ({ children }) => {
  const [expandedMenus, setExpandedMenus] = useState([]);
  const [selectedDrawerItem, setSelectedDrawerItem] = useState(null);
  const [activeMenuItems, setActiveMenuItems] = useState(new Set());
  const location = useLocation();
  
  // Add refs to track menu transition states
  const pendingMenuTransitionsRef = useRef(new Map());
  const lastMenuToggleTimeRef = useRef(0);
  const isProcessingMenuToggleRef = useRef(false);
  const lastClosedMenuRef = useRef({ menuId: null, timestamp: 0 });
  const debounceTimersRef = useRef(new Map());
  
  // Add ref to track most recently hovered menu item
  const lastHoveredMenuRef = useRef(null);
  const switchingBetweenMenusRef = useRef(false);
  
  // Track menu item pairs that were recently switched between
  const recentMenuSwitchesRef = useRef(new Map());

  // Add a function to debounce toggle events
  const debounce = (func, menuId, wait, immediate = false) => {
    // Clear existing timer for this menuId
    if (debounceTimersRef.current.has(menuId)) {
      clearTimeout(debounceTimersRef.current.get(menuId));
    }
    
    // Set new timer
    const timerId = setTimeout(() => {
      debounceTimersRef.current.delete(menuId);
      if (!immediate) func();
    }, wait);
    
    debounceTimersRef.current.set(menuId, timerId);
    
    // Immediate execution on first call
    if (immediate && !debounceTimersRef.current.has(menuId)) {
      func();
    }
  };

  // Update active state when route changes
  useEffect(() => {
    const updateActiveState = () => {
      // Find menu item matching current route
      const menuItems = document.querySelectorAll('.nav-item[href]');
      const newActiveItems = new Set();

      menuItems.forEach(item => {
        const href = item.getAttribute('href');
        if (href === location.pathname) {
          newActiveItems.add(href);
          
          // Get all parent menu sections
          let parent = item.closest('.menu-section');
          while (parent) {
            const menuId = parent.getAttribute('data-menu-id');
            if (menuId) {
              newActiveItems.add(menuId);
            }
            parent = parent.parentElement.closest('.menu-section');
          }
        }
      });

      setActiveMenuItems(newActiveItems);
    };

    updateActiveState();
  }, [location.pathname]);

  // Cleanup timers on unmount
  useEffect(() => {
    return () => {
      // Clear all debounce timers
      debounceTimersRef.current.forEach(timer => clearTimeout(timer));
      debounceTimersRef.current.clear();
      
      // Clear other tracking maps
      recentMenuSwitchesRef.current.clear();
      pendingMenuTransitionsRef.current.clear();
    };
  }, []);

  const handleCollapseOthers = (activeParentIds = []) => {
    // For clicked menu items, we still want to be able to open other drawers later
    // So don't restrict expandedMenus to only the ones in the active path
    // Check if we're in an actively selected state (after item click)
    const hasSelected = selectedDrawerItem !== null;
    
    if (hasSelected) {
      // If we have an item selected, still allow other top-level menus to be opened
      // Only collapse currently expanded menus that aren't top-level or in the active path
      setExpandedMenus(prev => 
        prev.filter(menuId => {
          // Always keep top-level menus 
          const isTopLevel = !menuId.includes('.');
          // Keep if it's in the active path
          const isInActivePath = activeParentIds.some(parentId => 
            menuId === parentId || menuId.startsWith(`${parentId}.`)
          );
          // Keep if it's top-level or in the active path
          return isTopLevel || isInActivePath;
        })
      );
    } else {
      // Regular behavior - only keep menus in the active path
      setExpandedMenus(prev => 
        prev.filter(menuId => 
          activeParentIds.some(parentId => 
            menuId === parentId || menuId.startsWith(`${parentId}.`)
          )
        )
      );
    }
  };

  const handleSelectItem = (item) => {
    setSelectedDrawerItem(item);
  };

  const processMenuToggleQueue = () => {
    if (pendingMenuTransitionsRef.current.size === 0) {
      isProcessingMenuToggleRef.current = false;
      return;
    }
    
    isProcessingMenuToggleRef.current = true;
    
    // Find the most recent menu toggle request
    let mostRecentMenuId = null;
    let mostRecentEventType = null;
    let mostRecentTime = 0;
    
    pendingMenuTransitionsRef.current.forEach((entry, menuId) => {
      if (entry.timestamp > mostRecentTime) {
        mostRecentTime = entry.timestamp;
        mostRecentMenuId = menuId;
        mostRecentEventType = entry.eventType;
      }
    });
    
    // Clear the queue
    pendingMenuTransitionsRef.current.clear();
    
    // Update the expanded menus based on the most recent request
    setExpandedMenus(prevExpanded => {
      // For submenuItemSelected events, always ensure the menu is expanded
      if (mostRecentEventType === 'submenuItemSelected') {
        if (!prevExpanded.includes(mostRecentMenuId)) {
          return [...prevExpanded, mostRecentMenuId];
        }
        return prevExpanded;
      }
      
      // For hover events with rapid transitions
      if (mostRecentEventType === 'hover') {
        // Skip if already expanded to prevent unnecessary renders
        if (prevExpanded.includes(mostRecentMenuId)) {
          return prevExpanded;
        }
        
        // If this menu was recently closed, be more conservative about reopening
        const recentlyClosed = lastClosedMenuRef.current.menuId === mostRecentMenuId &&
                              (Date.now() - lastClosedMenuRef.current.timestamp < 300);
        
        if (recentlyClosed) {
          // Don't reopen too quickly after closing
          return prevExpanded;
        }
        
        // Check if we're switching between menu items that have recently been involved in a switch
        const previousExpandedMenu = prevExpanded.length === 1 ? prevExpanded[0] : null;
        
        if (previousExpandedMenu) {
          const switchPairKey = `${previousExpandedMenu}:${mostRecentMenuId}`;
          const reverseSwitchPairKey = `${mostRecentMenuId}:${previousExpandedMenu}`;
          
          // Get the recent switch timestamps, if any
          const recentSwitchTime = recentMenuSwitchesRef.current.get(switchPairKey) || 0;
          const recentReverseSwitchTime = recentMenuSwitchesRef.current.get(reverseSwitchPairKey) || 0;
          
          // If we've been rapidly switching between these two menus
          if (Date.now() - recentSwitchTime < 800 || Date.now() - recentReverseSwitchTime < 800) {
            // Record this switch to track pattern
            recentMenuSwitchesRef.current.set(switchPairKey, Date.now());
            
            // Replace the previous one immediately with no delay for better responsiveness
            return [mostRecentMenuId];
          }
          
          // Record this switch
          recentMenuSwitchesRef.current.set(switchPairKey, Date.now());
        }
        
        // If the hover target is not already expanded, expand it
        // If switching between menus, only return the new one to prevent showing both briefly
        if (switchingBetweenMenusRef.current && prevExpanded.length > 0) {
          return [mostRecentMenuId];
        } else {
          return [...prevExpanded, mostRecentMenuId];
        }
      }
      
      // For mouseleave events with rapid transitions
      if (mostRecentEventType === 'mouseleave') {
        // Skip if not expanded to prevent unnecessary renders
        if (!prevExpanded.includes(mostRecentMenuId)) {
          return prevExpanded;
        }
        
        // If we just hovered a different menu and this is a leave from the previous one,
        // skip closing it as we're moving to another menu
        if (lastHoveredMenuRef.current && 
            lastHoveredMenuRef.current !== mostRecentMenuId && 
            Date.now() - lastMenuToggleTimeRef.current < 200) {
          // We're likely just moving between menu items - don't close the current one yet
          // The hover on the new item will handle switching
          return prevExpanded;
        }
        
        // Record this menu as recently closed
        lastClosedMenuRef.current = {
          menuId: mostRecentMenuId,
          timestamp: Date.now()
        };
        
        // Remove this specific menuId from expanded
        return prevExpanded.filter(id => id !== mostRecentMenuId);
      }
      
      // For click events - normal toggle behavior with improved responsiveness
      const willExpand = !prevExpanded.includes(mostRecentMenuId);
      
      if (willExpand) {
        // Improve scrolling behavior...
        requestAnimationFrame(() => scrollToExpandedSubmenu(mostRecentMenuId));
        return [...prevExpanded, mostRecentMenuId];
      } else {
        // Record this menu as recently closed
        lastClosedMenuRef.current = {
          menuId: mostRecentMenuId,
          timestamp: Date.now()
        };
        
        return prevExpanded.filter(id => !id.startsWith(mostRecentMenuId));
      }
    });
    
    // Release the lock after a very short delay
    setTimeout(() => {
      isProcessingMenuToggleRef.current = false;
      switchingBetweenMenusRef.current = false;
      
      // Check if we have new pending transitions
      if (pendingMenuTransitionsRef.current.size > 0) {
        processMenuToggleQueue();
      }
    }, 16); // Use 16ms (approx. one frame) for smoother responsiveness
  };

  const scrollToExpandedSubmenu = (menuId) => {
    const menuSection = document.querySelector(`[data-menu-id="${menuId}"]`);
    if (menuSection) {
      const submenu = menuSection.querySelector('.submenu.expanded');
      if (submenu) {
        const sidebarContent = document.querySelector('.sidebar-content');
        const sidebarFooter = document.querySelector('.sidebar-footer');
        const submenuRect = submenu.getBoundingClientRect();
        const footerTop = sidebarFooter.getBoundingClientRect().top;
        const footerHeight = sidebarFooter.offsetHeight;
        const menuSectionTop = menuSection.getBoundingClientRect().top;
        
        if (menuSectionTop > (window.innerHeight / 2)) {
          const initialScroll = menuSectionTop - 100;
          sidebarContent.scrollBy({ 
            top: initialScroll, 
            behavior: 'smooth'
          });
          
          setTimeout(() => {
            const updatedSubmenuBottom = submenu.getBoundingClientRect().bottom;
            if (updatedSubmenuBottom > (footerTop - footerHeight)) {
              const scrollAmount = updatedSubmenuBottom - (footerTop - footerHeight) + 20;
              sidebarContent.scrollBy({ 
                top: scrollAmount, 
                behavior: 'smooth'
              });
            }
          }, 300); // Slightly shorter wait time
        } else {
          if (submenuRect.bottom > (footerTop - footerHeight)) {
            const scrollAmount = submenuRect.bottom - (footerTop - footerHeight) + 20;
            sidebarContent.scrollBy({ 
              top: scrollAmount, 
              behavior: 'smooth'
            });
          }
        }
      }
    }
  };

  const toggleMenu = (event) => {
    // Handle different event types (click, hover, submenuItemSelected, mouseleave)
    const menuId = typeof event === 'object' ? event.menuId : event;
    const eventType = typeof event === 'object' ? event.type : 'click';
    const currentTime = Date.now();
    
    // Update most recently hovered menu
    if (eventType === 'hover') {
      // Check if we're switching from one menu to another
      if (expandedMenus.length > 0 && !expandedMenus.includes(menuId)) {
        switchingBetweenMenusRef.current = true;
      }
      
      lastHoveredMenuRef.current = menuId;
    }
    
    // For click events, bypass all debouncing and processing for immediate response
    if (eventType === 'click' || eventType === 'submenuItemSelected') {
      // Directly add to pending transitions
      pendingMenuTransitionsRef.current.set(menuId, {
        eventType: eventType,
        timestamp: currentTime
      });
      
      // Process immediately for better responsiveness
      if (!isProcessingMenuToggleRef.current) {
        processMenuToggleQueue();
      }
      return;
    }
    
    // If this is a hover event for an already open menu, don't do anything
    if (eventType === 'hover' && expandedMenus.includes(menuId)) {
      return;
    }
    
    // Skip rapid repeated toggles for the same menu to prevent flicker
    // Use a shorter time window for better responsiveness
    if (currentTime - lastMenuToggleTimeRef.current < 80 && eventType === 'hover') {
      return;
    }
    
    // Skip this mouseleave event if it's followed too quickly by a hover event
    if (eventType === 'mouseleave') {
      // Use debouncing for mouseleave with shorter timeout
      debounce(() => {
        // Store this event for reference
        const currentMenuLeave = {
          menuId,
          timestamp: Date.now() // Use current time, not the initial event time
        };
        
        // Skip processing if the user has already hovered over another menu
        // This prevents "close, then open, then close again" when moving between items
        if (lastHoveredMenuRef.current && lastHoveredMenuRef.current !== menuId && 
            Date.now() - lastMenuToggleTimeRef.current < 200) {
          return;
        }
        
        // Check if a hover event came in while we were waiting
        const pendingHover = Array.from(pendingMenuTransitionsRef.current.entries())
          .find(([id, data]) => data.eventType === 'hover' && 
                               id !== menuId && 
                               data.timestamp > currentMenuLeave.timestamp - 200);
        
        if (!pendingHover) {
          // Add to pending transitions with timestamp
          pendingMenuTransitionsRef.current.set(menuId, {
            eventType: eventType,
            timestamp: currentMenuLeave.timestamp
          });
          
          // If we're not already processing, start processing the queue
          if (!isProcessingMenuToggleRef.current) {
            processMenuToggleQueue();
          }
        }
      }, menuId, 100); // Shorter debounce for mouseleave (100ms instead of 300ms)
      
      return;
    }
    
    // For hover events, use a shorter debounce for better responsiveness
    if (eventType === 'hover') {
      debounce(() => {
        // Update the last toggle time
        lastMenuToggleTimeRef.current = Date.now();
        
        // Add to pending transitions with timestamp
        pendingMenuTransitionsRef.current.set(menuId, {
          eventType: eventType,
          timestamp: Date.now() // Use updated timestamp
        });
        
        // If we're not already processing, start processing the queue
        if (!isProcessingMenuToggleRef.current) {
          processMenuToggleQueue();
        }
      }, menuId, 20, true); // Shorter debounce for hover (20ms instead of 50ms), execute immediately
      
      return;
    }
    
    // For any other type of event, fall back to the normal processing
    // Update the last toggle time
    lastMenuToggleTimeRef.current = currentTime;
    
    // Add to pending transitions with timestamp
    pendingMenuTransitionsRef.current.set(menuId, {
      eventType: eventType,
      timestamp: currentTime
    });
    
    // If we're not already processing, start processing the queue
    if (!isProcessingMenuToggleRef.current) {
      processMenuToggleQueue();
    }
  };

  return children({
    expandedMenus,
    selectedDrawerItem,
    activeMenuItems,
    handleCollapseOthers,
    handleSelectItem,
    toggleMenu
  });
};

export default MenuStateManager; 