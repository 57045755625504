import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const TableBody = ({
  data = [],
  columns,
  classProperty,
  valueProperty = "_id",
  onRowClick,
  onMouseEnter,
  onMouseLeave,
  cellClassName = "",
}) => {
  // Safely render cell content with fallback for null/undefined values
  const renderCell = (item, column) => {
    // For custom content renderers, pass the item directly
    if (column.content) return column.content(item);

    // Use lodash's get() for safe property access
    const value = _.get(item, column.path);

    // Return a fallback dash for null or undefined values
    return value !== undefined && value !== null ? value : "-";
  };

  // Create a unique key for each cell to help with React rendering
  const createKey = (item, column) => {
    return item[valueProperty] + (column.path || column.key);
  };

  // Generate consistent row props with proper accessibility attributes
  const getRowProps = (item) => ({
    className: `
      ${onRowClick ? "clickable" : ""}
      ${item[classProperty] || ""}
    `.trim(),
    onClick: onRowClick ? () => onRowClick(item) : undefined,
    onMouseEnter: onMouseEnter ? () => onMouseEnter(item) : undefined,
    onMouseLeave: onMouseLeave ? () => onMouseLeave(item) : undefined,
    key: item[valueProperty],
    role: onRowClick ? "button" : undefined,
    tabIndex: onRowClick ? 0 : undefined,
  });

  return (
    <tbody>
      {data.map((item) => (
        <tr {...getRowProps(item)}>
          {columns.map((column) => (
            <td
              className={`${column.className || ""} ${cellClassName}`}
              key={createKey(item, column)}
            >
              {renderCell(item, column)}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

TableBody.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array.isRequired,
  classProperty: PropTypes.string,
  valueProperty: PropTypes.string,
  onRowClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  cellClassName: PropTypes.string,
};

export default TableBody;
