import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useMatch, useLocation } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import { usePermitted } from "../../../../common/components/permissions/permissions";
import FontAwesome from "react-fontawesome";
import NavDropdown from "react-bootstrap/NavDropdown";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Logo from "../../../../config/images/Neutral Markets Logo - Light.png";
import IconLogo from "../../../../config/images/Neutral Markets Logomark - Light.png";
import {
  openMarkets,
  closeMarkets,
  getMarketState,
} from "../../../../common/services/tradingEngine/marketStateService";
import { toast } from "react-toastify";
import ExpandedSideNav from "./ExpandedSideNav";
import CollapsedSideNav from "./CollapsedSideNav";
import { MenuStateManager, MenuItemWithTooltip, MenuSection } from "../../Menu";
import "../styles/Sidebar.css";

const SideNav = ({ unapprovedMarkCount, unapprovedTradeCount, navTheme }) => {
  const { keycloak, initialized } = useKeycloak();
  const { t } = useTranslation();
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [menuPositions, setMenuPositions] = useState({});
  const [expandedMenus, setExpandedMenus] = useState([]);
  const [selectedDrawerItem, setSelectedDrawerItem] = useState(null);
  const location = useLocation();
  const isOnTradingPage = useMatch("/trading");

  // Constants
  const VERSION = process.env.REACT_APP_VERSION;
  const ENABLE_TRADING = process.env.REACT_APP_ENABLE_TRADING === "true";

  // Move hooks outside of useMemo
  const isAdmin = usePermitted(["administrator", "operator"]);
  const isApiManageMarks = usePermitted(["api-manage-marks"]);
  const isApiManageTrades = usePermitted(["api-manage-trades"]);
  const isApiManageExternalData = usePermitted(["api-manage-external-data"]);
  const isApiApproveMarks = usePermitted(["api-approve-marks"]);
  const isApiApproveTrades = usePermitted(["api-approve-trades"]);
  const isAdministrator = usePermitted(["administrator"]);
  const isEnergyDailySubscriber = usePermitted([
    "administrator",
    "operator",
    "energy-daily-subscriber",
  ]);
  const isMarketDataUser = usePermitted([
    "administrator",
    "operator",
    "market-data-provider",
    "market-data-subscriber",
  ]);

  const isTrader = usePermitted(["administrator"]); //temporary for now
  const isTraderAdmin = usePermitted(["administrator"]); //temporary for now

  // Combine permissions into object after hooks are called
  const permissions = React.useMemo(
    () => ({
      authenticated: initialized && keycloak.authenticated,
      showAdmin: isAdmin,
      showUserAdmin: isAdmin,
      showProductAdmin: isAdmin,
      showMarketAdmin: isAdmin,
      showMarketData: true, // We want these menu items to always render and show the unauthorized page if the user doesn't have access
      showSubmitMarks: isApiManageMarks,
      showSubmitTrades: isApiManageTrades,
      showSubmitExternalData: isApiManageExternalData,
      showReviewMarks: isApiApproveMarks,
      showReviewTrades: isApiApproveTrades,
      showTrading: ENABLE_TRADING && isTrader, // Added ENABLE_TRADING check
      showTradingAdmin: isTraderAdmin,
      showEnergyDaily: isEnergyDailySubscriber,
      showMarketDataAccess: isMarketDataUser,
    }),
    [
      initialized,
      isAdmin,
      isApiManageMarks,
      isApiManageTrades,
      isApiManageExternalData,
      isApiApproveMarks,
      isApiApproveTrades,
      isAdministrator,
      isEnergyDailySubscriber,
      isMarketDataUser,
      ENABLE_TRADING,
      isTrader,
      isTraderAdmin,
    ]
  );

  const login =
    keycloak.authenticated && keycloak.tokenParsed.preferred_username;
  const showMarketControl = permissions.showTradingAdmin && isOnTradingPage;

  const handleCloseAboutModel = () => setShowAboutModal(false);
  const handleShowAboutModel = () => setShowAboutModal(true);

  // Enhanced menuOpenTimes ref for more responsive drawer tracking
  const menuOpenTimes = useRef({});
  const menuLeaveTimesRef = useRef({});
  const isMenuSwitchingRef = useRef(false);

  const toggleSidebar = () => {
    if (isExpanded) {
      // When collapsing, find the most recently expanded top-level menu
      if (expandedMenus.length > 0) {
        // Get the most recently added menu ID
        const lastExpandedMenu = expandedMenus[expandedMenus.length - 1];

        // Find the top-level menu by taking everything before the first dot
        // If there's no dot, it's already a top-level menu
        const topLevelMenu = lastExpandedMenu.includes(".")
          ? lastExpandedMenu.split(".")[0]
          : lastExpandedMenu;

        console.log(
          `Transitioning to collapsed view. Keeping only menu open: ${topLevelMenu}`
        );

        // IMPORTANT: Reset the expandedMenus array completely and only add the top-level menu
        // This ensures only one drawer is open in collapsed view
        setExpandedMenus([topLevelMenu]);
      } else {
        // No menus are expanded, so keep it that way
        setExpandedMenus([]);
      }
    }
    // Toggle the expanded state
    setIsExpanded(!isExpanded);
  };

  const toggleSubmenu = (menuName) => {
    // If no menuName is provided (clicking a NavLink) or clicking the same menu, close all submenus
    if (!menuName || menuName === activeSubmenu) {
      setActiveSubmenu(null);
      return;
    }

    // Get the position of the clicked menu item
    const menuItem = document.querySelector(
      `[data-menu-trigger="${menuName}"]`
    );
    if (menuItem) {
      const rect = menuItem.getBoundingClientRect();
      setMenuPositions({
        ...menuPositions,
        [menuName]: rect.top,
      });
    }
    setActiveSubmenu(menuName);
  };

  // Add onClick handler to all NavLinks to close submenus
  const handleNavLinkClick = () => {
    setActiveSubmenu(null);
  };

  const handleOpenMarkets = async () => {
    try {
      await openMarkets();
      toast.success(t("MarketsOpened"));
    } catch (ex) {
      console.error(t("ErrorSaving"), ex);
      toast.error(t("ErrorSaving"));
    }
  };

  const handleCloseMarkets = async () => {
    try {
      await closeMarkets();
      toast.success(t("MarketsClosed"));
    } catch (ex) {
      console.error(t("ErrorSaving"), ex);
      toast.error(t("ErrorSaving"));
    }
  };

  const handleCollapseOthers = (activeParentIds = []) => {
    setExpandedMenus((prev) =>
      prev.filter((menuId) =>
        // Keep menu expanded only if it's in the active item's hierarchy
        activeParentIds.some(
          (parentId) => menuId === parentId || menuId.startsWith(`${parentId}.`)
        )
      )
    );
  };

  const handleSelectItem = (item) => {
    setSelectedDrawerItem(item);
  };

  // Enhanced toggleMenu function for better responsiveness
  const toggleMenu = (menuId, isHoverEvent = false) => {
    setExpandedMenus((prevExpanded) => {
      // If we're in collapsed mode, implement drawer management
      if (!isExpanded) {
        // If menuId is empty, close all menus (used when navigating)
        if (menuId === "") {
          return [];
        }

        // Handle nested submenu toggle within a drawer
        if (
          isHoverEvent &&
          isHoverEvent.type === "nestedSubmenuToggle" &&
          isHoverEvent.menuId &&
          isHoverEvent.isWithinDrawer
        ) {
          // If the submenu is already expanded, collapse it
          if (prevExpanded.includes(isHoverEvent.menuId)) {
            return prevExpanded.filter((id) => id !== isHoverEvent.menuId);
          }
          // Otherwise expand it while keeping the parent drawer open
          else {
            return [...prevExpanded, isHoverEvent.menuId];
          }
        }

        // Handle submenuItemSelected event - ensure the drawer stays open
        if (
          isHoverEvent &&
          isHoverEvent.type === "submenuItemSelected" &&
          isHoverEvent.menuId
        ) {
          // Ensure the drawer stays open when a submenu item is selected
          if (!prevExpanded.includes(isHoverEvent.menuId)) {
            return [...prevExpanded, isHoverEvent.menuId];
          }
          return prevExpanded;
        }

        // Handle mouseleave event - close only the specific drawer
        if (
          isHoverEvent &&
          isHoverEvent.type === "mouseleave" &&
          isHoverEvent.menuId
        ) {
          // Check if the drawer was just recently opened
          const now = Date.now();
          const lastOpenTime = menuOpenTimes.current[isHoverEvent.menuId] || 0;

          // Record this leave time for reference
          menuLeaveTimesRef.current[isHoverEvent.menuId] = now;

          // If the drawer was just opened very recently (within 200ms), don't close it
          // This helps prevent flickering when mouse is moving over the boundary
          if (now - lastOpenTime < 200) {
            return prevExpanded;
          }

          // Check if we're actively switching between menus
          if (isMenuSwitchingRef.current) {
            // Let the hover event on the new item handle the transition
            return prevExpanded;
          }

          // Ensure we close the drawer when mouse leaves, but only if it's actually expanded
          if (prevExpanded.includes(isHoverEvent.menuId)) {
            return prevExpanded.filter((id) => id !== isHoverEvent.menuId);
          }
          return prevExpanded;
        }

        // Handle click outside event - close the specific drawer
        if (
          isHoverEvent &&
          isHoverEvent.type === "click" &&
          isHoverEvent.isClickOutside
        ) {
          return prevExpanded.filter((id) => id !== menuId);
        }

        // Handle hover event - close other drawers and open this one
        if (isHoverEvent && isHoverEvent.type === "hover") {
          // If this menu is already open, keep it open without changes
          if (prevExpanded.includes(menuId)) {
            return prevExpanded;
          }

          // Record the open time for this menu
          menuOpenTimes.current[menuId] = Date.now();

          // Check if we're switching between menus
          const isMenuSwitch = isHoverEvent.isMenuSwitch === true;

          // Set the menu switching state for reference
          isMenuSwitchingRef.current = isMenuSwitch;

          // After a short delay, reset the switching state
          setTimeout(() => {
            isMenuSwitchingRef.current = false;
          }, 150);

          // If we're switching between drawers, use a more immediate approach
          if (isMenuSwitch && prevExpanded.length > 0) {
            // Replace the current drawer with the new one immediately
            return [menuId];
          }

          // For regular hover events, ensure only this menu is open
          // This prevents multiple drawers from opening simultaneously
          return [menuId];
        }

        // If this is a regular hover event (backward compatibility)
        if (isHoverEvent === true) {
          // If the menu is already open, keep it open without changes
          if (prevExpanded.includes(menuId)) {
            return prevExpanded;
          }

          // Record the open time for this menu
          menuOpenTimes.current[menuId] = Date.now();

          // For hover events, ensure only this menu is open
          return [menuId];
        }

        // For click events, strictly enforce single drawer rule
        if (!isHoverEvent) {
          // If this menu is already open, close it (toggle behavior)
          if (prevExpanded.includes(menuId)) {
            return []; // Close it when clicked again for better UX
          }

          // Record the open time for this menu
          menuOpenTimes.current[menuId] = Date.now();

          // Otherwise, open only this menu (single drawer rule for clicks)
          return [menuId];
        }

        // Fallback case - ensure only one drawer is open
        menuOpenTimes.current[menuId] = Date.now();
        return [menuId];
      }

      // For expanded mode, use the existing behavior but with improved responsiveness
      const willExpand = !prevExpanded.includes(menuId);
      const newExpandedMenus = willExpand
        ? [...prevExpanded, menuId]
        : prevExpanded.filter((id) => !id.startsWith(menuId));

      // If we're expanding a menu, scroll after using requestAnimationFrame for smoother experience
      if (willExpand) {
        // Record the open time for this menu
        menuOpenTimes.current[menuId] = Date.now();

        requestAnimationFrame(() => {
          const menuSection = document.querySelector(
            `[data-menu-id="${menuId}"]`
          );
          if (menuSection) {
            const submenu = menuSection.querySelector(".submenu.expanded");
            if (submenu) {
              const sidebarContent = document.querySelector(".sidebar-content");
              const sidebarFooter = document.querySelector(".sidebar-footer");
              const submenuRect = submenu.getBoundingClientRect();
              const footerTop = sidebarFooter.getBoundingClientRect().top;
              const footerHeight = sidebarFooter.offsetHeight;
              const menuSectionTop = menuSection.getBoundingClientRect().top;

              // For Admin menu or other bottom menus, scroll the section into better view first
              if (menuSectionTop > window.innerHeight / 2) {
                const initialScroll = menuSectionTop - 100; // Position menu higher up
                sidebarContent.scrollBy({
                  top: initialScroll,
                  behavior: "smooth",
                });

                // Wait for initial scroll to complete before checking submenu visibility
                setTimeout(() => {
                  const updatedSubmenuBottom =
                    submenu.getBoundingClientRect().bottom;
                  if (updatedSubmenuBottom > footerTop - footerHeight) {
                    const scrollAmount =
                      updatedSubmenuBottom - (footerTop - footerHeight) + 20;
                    sidebarContent.scrollBy({
                      top: scrollAmount,
                      behavior: "smooth",
                    });
                  }
                }, 150); // Reduced wait time for faster response
              } else {
                // Handle normal case for menus higher up
                if (submenuRect.bottom > footerTop - footerHeight) {
                  const scrollAmount =
                    submenuRect.bottom - (footerTop - footerHeight) + 20;
                  sidebarContent.scrollBy({
                    top: scrollAmount,
                    behavior: "smooth",
                  });
                }
              }
            }
          }
        });
      }

      return newExpandedMenus;
    });
  };

  useEffect(() => {
    setSelectedDrawerItem(null);
  }, [location.pathname]);

  // Add a useEffect to ensure only one drawer is open in collapsed view
  useEffect(() => {
    // Only run this effect when the sidebar is collapsed
    if (!isExpanded) {
      // If there are multiple expanded menus in collapsed view, keep only the first one
      if (expandedMenus.length > 1) {
        console.log(
          "Multiple drawers detected in collapsed view - enforcing single drawer rule"
        );
        // Keep only the first menu in the array (most recently added)
        setExpandedMenus([expandedMenus[0]]);
      }
    }
  }, [isExpanded, expandedMenus]);

  return (
    <div className="sidebar">
      <MenuStateManager>
        {({
          expandedMenus,
          selectedDrawerItem,
          activeMenuItems,
          handleCollapseOthers,
          handleSelectItem,
          toggleMenu,
          showTrading,
          showTradingAdmin,
        }) => (
          <nav
            className={`sidebar-nav ${isExpanded ? "expanded" : "collapsed"}`}
          >
            {isExpanded ? (
              <>
                <div className="sidebar-header">
                  <div className="sidebar-brand">
                    <Link to="/">
                      <img
                        src={isExpanded ? Logo : IconLogo}
                        alt="Neutral Markets"
                        className={isExpanded ? "full-logo" : "icon-logo"}
                      />
                    </Link>
                  </div>
                  <button
                    className="toggle-button"
                    onClick={toggleSidebar}
                    aria-label={t("CollapseSidebar")}
                  >
                    <FontAwesome name="chevron-left" />
                  </button>
                </div>
                <ExpandedSideNav
                  expandedMenus={expandedMenus}
                  onMenuToggle={toggleMenu}
                  handleNavLinkClick={handleNavLinkClick}
                  handleCollapseOthers={handleCollapseOthers}
                  permissions={permissions}
                  showMarketControl={showMarketControl}
                  handleOpenMarkets={handleOpenMarkets}
                  handleCloseMarkets={handleCloseMarkets}
                  handleShowAboutModel={handleShowAboutModel}
                  login={login}
                  keycloak={keycloak}
                  unapprovedMarkCount={unapprovedMarkCount}
                  unapprovedTradeCount={unapprovedTradeCount}
                  activeMenuItems={activeMenuItems}
                  showTrading={showTrading}
                  showTradingAdmin={showTradingAdmin}
                />
              </>
            ) : (
              <CollapsedSideNav
                expandedMenus={expandedMenus}
                onMenuToggle={toggleMenu}
                handleNavLinkClick={handleNavLinkClick}
                handleCollapseOthers={handleCollapseOthers}
                permissions={permissions}
                showMarketControl={showMarketControl}
                handleOpenMarkets={handleOpenMarkets}
                handleCloseMarkets={handleCloseMarkets}
                handleShowAboutModel={handleShowAboutModel}
                login={login}
                keycloak={keycloak}
                unapprovedMarkCount={unapprovedMarkCount}
                unapprovedTradeCount={unapprovedTradeCount}
                selectedDrawerItem={selectedDrawerItem}
                onSelectItem={handleSelectItem}
                onToggleSidebar={toggleSidebar}
                activeMenuItems={activeMenuItems}
                showTrading={showTrading}
                showTradingAdmin={showTradingAdmin}
              />
            )}
          </nav>
        )}
      </MenuStateManager>

      <Modal show={showAboutModal} onHide={handleCloseAboutModel}>
        <Modal.Header closeButton>
          <Modal.Title>© Neutral Markets 2025</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Version")}: {VERSION}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SideNav;
