import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import FontAwesome from "react-fontawesome";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const MenuItemWithTooltip = ({
  to,
  icon,
  text,
  onClick,
  isButton = false,
  isSubmenuItem = false,
  hasSubmenu = false,
  isExpanded = false,
  className = "",
  parentIds = [],
  onCollapseOthers,
  onSelectItem,
  parentId,
  activeMenuItems,
  showTooltip = true,
  isInExpandedSubmenu = true,
}) => {
  const location = useLocation();

  // Check if this item is active based on the current route
  // This works for both direct clicks and redirects
  const isActive =
    !isButton &&
    to &&
    // Exact path match
    (location.pathname === to ||
      // Path with trailing slash
      location.pathname === `${to}/` ||
      // Check if this path is in the activeMenuItems set (used for parent highlights)
      activeMenuItems?.has(to));

  // When this item is active and is a submenu item, notify parent trigger
  useEffect(() => {
    if (isActive && isSubmenuItem && parentId && onSelectItem) {
      // Notify the parent trigger that this submenu item is active
      onSelectItem({ icon, text, parentId, isActive: true });
      console.log(`Active submenu item: ${text} with parent ${parentId}`);
    }
  }, [
    isActive,
    isSubmenuItem,
    parentId,
    onSelectItem,
    icon,
    text,
    location.pathname,
  ]);

  // When redirecting from Home to another route, ensure parent menus are expanded
  useEffect(() => {
    if (isActive && isSubmenuItem && parentIds.length > 0 && onCollapseOthers) {
      // Ensure parent menus are expanded when this item becomes active via redirect
      onCollapseOthers(parentIds);
    }
  }, [isActive, isSubmenuItem, parentIds, onCollapseOthers, location.pathname]);

  const handleClick = (e) => {
    if (!hasSubmenu && !isButton) {
      // Notify about selection if this is a submenu item (in a drawer)
      if (onSelectItem && isSubmenuItem) {
        onSelectItem({ icon, text, parentId, isActive: true });
        console.log(`Clicked submenu item: ${text} with parent ${parentId}`);
      }

      // Only call onCollapseOthers when navigating, not when an item is already active
      // This prevents locking other drawers from opening
      if (onCollapseOthers && !isActive) {
        onCollapseOthers(parentIds);
      }
    }

    if (onClick) {
      onClick(e);
    }
  };

  // If this is a button in a submenu but the submenu is not expanded, don't render
  if (isButton && isSubmenuItem && !isInExpandedSubmenu) {
    return null;
  }

  const content = (
    <div className="nav-item-content">
      <FontAwesome name={icon} />
      <span className="nav-item-text">{text}</span>
      {hasSubmenu && (
        <FontAwesome
          name="chevron-right"
          className={`submenu-arrow ${isExpanded ? "expanded" : ""}`}
        />
      )}
    </div>
  );

  // Add button-item class for buttons and never add active class
  const fullClassName = `nav-item ${isButton ? "button-item" : ""} ${
    isSubmenuItem ? "submenu-item" : ""
  } ${hasSubmenu ? "submenu-trigger" : ""} ${className} ${
    !isButton && isActive ? "active" : ""
  }`;

  const tooltipContent = React.isValidElement(text)
    ? text.props?.children?.[0] || ""
    : String(text || "");

  // Return the appropriate component with or without tooltip
  const renderComponent = isButton ? (
    <button className={fullClassName} onClick={handleClick}>
      {content}
    </button>
  ) : (
    <NavLink to={to} className={fullClassName} onClick={handleClick} end>
      {content}
    </NavLink>
  );

  // Only wrap with OverlayTrigger if we need to show tooltip
  if (!isExpanded && showTooltip) {
    return (
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip
            style={{
              position: "fixed", // Prevent layout shifts
              pointerEvents: "none", // Prevent tooltip from interfering with hover states
            }}
            popperConfig={{
              modifiers: [
                {
                  name: "preventOverflow",
                  options: {
                    boundariesElement: "viewport",
                    padding: 8,
                  },
                },
                {
                  name: "offset",
                  options: {
                    offset: [0, 8], // Maintain consistent spacing
                  },
                },
              ],
            }}
          >
            {tooltipContent}
          </Tooltip>
        }
        container={document.body} // Render tooltips at body level to prevent layout shifts
      >
        {renderComponent}
      </OverlayTrigger>
    );
  }

  return renderComponent;
};

export default MenuItemWithTooltip;
