import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { SideNav } from "./components/Navigation";
import NotFound from "./pages/notFound";
import WelcomePage from "./pages/Homepage";
import UserProfile from "./pages/userProfile";
import Maintenance from "./pages/maintenance";
import ProtectedRoute from "../common/components/routes/protectedRoute";
import AuthAdmin from "../admin/auth/authAdmin";
import ProductAdmin from "../admin/products/productAdmin";
import MarketAdmin from "../admin/markets/marketAdmin";
import TradingAdmin from "../admin/trading/tradingAdmin";
import Loading from "./../common/components/loading/loading";
import "react-toastify/dist/ReactToastify.css";
import { EnvironmentIndicator } from "./components/Environment";
import { MessageHandler } from "./components/common";
import SubmitTradesAuthWrapper from "./../marketData/submitTrades/submitTradesAuthWrapper";
import ReviewTradesAuthWrapper from "./../marketData/reviewTrades/reviewTradesAuthWrapper";
import SubmitMarksAuthWrapper from "./../marketData/submitMarks/submitMarksAuthWrapper";
import ReviewMarksAuthWrapper from "./../marketData/reviewMarks/reviewMarksAuthWrapper";
import SubmitExternalDataAuthWrapper from "./../marketData/submitExternalData/submitExternalDataAuthWrapper";
import MarketDataAuthWrapper from "./../marketData/marketData/marketDataAuthWrapper";
import HistoricalMarketDataAuthWrapper from "./../marketData/historicalMarketData/historicalMarketDataAuthWrapper";
import HistoricalTradeDataAuthWrapper from "./../marketData/historicalTradeData/historicalTradeDataAuthWrapper";
import DashboardContainer from "./../dashboards/dashboardContainer";
import MarketDataTicker from "./../marketData/marketDataTicker";
import TradingComponent from "./../trading/tradingComponent";
import { useKeycloak } from "@react-keycloak/web";
import AlbertaEnvironmentalTrades from "../dashboards/albertaEnvironmental/albertaEnvironmentalTrades";
import AlbertaEnvironmentalIssues from "../dashboards/albertaEnvironmental/albertaEnvironmentalIssues";
import AlbertaEnvironmentalStatusChanges from "../dashboards/albertaEnvironmental/albertaEnvironmentalStatusChanges";
import AlbertaEnvironmentalRegistry from "../dashboards/albertaEnvironmental/albertaEnvironmentalRegistry";
import AlbertaPowerReport from "../dashboards/albertaPowerReport/albertaPowerReport";
import { getMarketsWithAttributes } from "../common/services/markets/marketService";
import { getMarketGroupMarkets } from "../common/services/markets/marketGroupMarketService";
import { getProducts } from "../common/services/products/productService";
import { getUserAccountSubscriptionsByUserAccount } from "../common/services/marketdata/userAccountSubscriptionService";
import { getVenues } from "../common/services/markets/venueService";
import { getTermSchedules } from "../common/services/markets/termScheduleService";
import { getUnapprovedMarkCount } from "../common/services/marketdata/markService";
import { getUnapprovedTradeCount } from "../common/services/marketdata/tradeService";
import { usePermitted } from "../common/components/permissions/permissions";
import "./styles/App.css";
import FontAwesome from "react-fontawesome";

const App = () => {
  //Markets with Attributes
  const [marketsWithAttributes, setMarketsWithAttributes] = useState(null);
  const [theme, setTheme] = useState("dark");
  const [isFooterCollapsed, setIsFooterCollapsed] = useState(true);
  const location = useLocation();

  // Define navTheme here at the top of the component
  const navTheme = "navbar-dark"; //Same for both light and dark themes

  const { keycloak, initialized } = useKeycloak();
  const userAccountId =
    initialized && keycloak.tokenParsed.OriginatingUserAccountID;

  // Check if ticker should be shown on current route
  const isTickerRoute = () => {
    const tickerRoutes = [
      '/',
      '/marketdata',
      '/historicalmarketdata',
      '/dashboards'
    ];
    // This pattern ensures we match exactly what the Routes pattern does
    return tickerRoutes.some(route => {
      if (route === '/') {
        return location.pathname === '/';
      }
      return location.pathname === route || location.pathname.startsWith(route + '/');
    });
  };

  const approveMarks = usePermitted(["api-approve-marks"]);
  const approveTrades = usePermitted(["api-approve-trades"]);

  const queryMarkets = usePermitted(["api-query-markets"]);
  const queryMarketGroups = usePermitted(["api-query-market-groups"]);
  const queryProducts = usePermitted(["api-query-products"]);
  const queryVenues = usePermitted(["api-query-venues"]);
  const queryTermSchedules = usePermitted(["api-query-term-schedules"]);
  const queryUserAccountSubscriptions = usePermitted([
    "api-query-user-account-market-subscriptions",
  ]);
  const queryMarks = usePermitted(["api-query-marks"]);

  const isSubscriber = usePermitted([
    "market-data-subscriber",
    "market-data-provider",
    "operator",
    "administrator",
  ]);

  const isDailyReportSubscriber = usePermitted([
    "energy-daily-subscriber",
    "operator",
    "administrator",
  ]);

  const isDailyReportDataSubscriber = usePermitted([
    "energy-daily-data-subscriber",
    "operator",
    "administrator",
  ]);

  const enableMessaging =
    process.env.REACT_APP_ENABLE_SIGNALR_MESSAGING === "true";

  //Markets with Attributes
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (initialized && queryMarkets) {
          const result = await getMarketsWithAttributes();
          setMarketsWithAttributes(result);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [initialized, queryMarkets]);

  //Markets Group Markets
  const [marketGroupMarkets, setMarketGroupMarkets] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (initialized && queryMarketGroups) {
          const result = await getMarketGroupMarkets();
          setMarketGroupMarkets(result);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [initialized, queryMarketGroups]);

  //Products
  const [products, setProducts] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (initialized && queryProducts) {
          const result = await getProducts();
          setProducts(result);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [initialized, queryProducts]);

  //User Account Subscriptions By UserAccount
  const [
    userAccountSubscriptionsByUserAccount,
    setUserAccountSubscriptionsByUserAccount,
  ] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (initialized && queryUserAccountSubscriptions) {
          const result = await getUserAccountSubscriptionsByUserAccount(
            userAccountId
          );
          setUserAccountSubscriptionsByUserAccount(result);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [initialized, queryUserAccountSubscriptions, userAccountId]);

  //Venues
  const [venues, setVenues] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (initialized && queryVenues) {
          const result = await getVenues();
          setVenues(result);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [initialized, queryVenues]);

  //Term Schedules
  const [termSchedules, setTermSchedules] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (initialized && queryTermSchedules) {
          const result = await getTermSchedules();
          setTermSchedules(result);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [initialized, queryTermSchedules]);

  //Unapproved Mark Count
  const [unapprovedMarkCount, setUnapprovedMarkCount] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (initialized && approveMarks) {
          const result = await getUnapprovedMarkCount();
          setUnapprovedMarkCount(result);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 300000);

    return () => {
      clearInterval(intervalId); //This is important
    };
  }, [initialized, approveMarks]);

  //Unapproved Trade Count
  const [unapprovedTradeCount, setUnapprovedTradeCount] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (initialized && approveTrades) {
          const result = await getUnapprovedTradeCount();
          setUnapprovedTradeCount(result);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 300000);

    return () => {
      clearInterval(intervalId); //This is important
    };
  }, [initialized, approveTrades]);

  // Reset footer collapsed state when navigating between routes
  useEffect(() => {
    // When navigating to a ticker route, ensure ticker is initially collapsed
    if (isTickerRoute()) {
      setIsFooterCollapsed(true);
    }
  }, [location.pathname]);

  if (
    !keycloak.authenticated ||
    (queryMarketGroups && !marketGroupMarkets) ||
    (queryMarkets && !marketsWithAttributes) ||
    (queryProducts && !products) ||
    (queryVenues && !venues) ||
    (queryTermSchedules && !termSchedules)
  ) {
    return (
      <div style={{ height: "100vh" }}>
        <Loading />
      </div>
    );
  }

  /******************************************* */
  /* Theme block; stub for now, but will allow */
  /* for theme switching once tied into        */
  /******************************************* */
  const otherTheme = theme === "dark" ? "light" : "dark";
  document.body.classList.remove(otherTheme + "-theme");
  document.body.classList.add(theme + "-theme");
  document.body.setAttribute("data-bs-theme", theme);
  /******************************************* */

  return (
    <div>
      <ToastContainer theme={theme} />
      {enableMessaging && <MessageHandler />}
      <EnvironmentIndicator />

      <div className="app-container">
        <SideNav
          unapprovedMarkCount={unapprovedMarkCount}
          unapprovedTradeCount={unapprovedTradeCount}
          navTheme={navTheme}
        />

        {/* Main Content */}
        <div className="main-content">
          <div className="content-area">
            <Routes>
              <Route
                path="/"
                element={
                  isDailyReportSubscriber ? (
                    <Navigate replace to="/albertaenergydaily/" />
                  ) : isSubscriber ? (
                    <Navigate replace to="/marketdata/" />
                  ) : (
                    <Navigate replace to="/dashboards/albertaenvironmental" />
                  )
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <UserProfile theme={theme} setTheme={setTheme} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/auth/*"
                element={
                  <ProtectedRoute
                    permittedRoles={["administrator", "operator"]}
                  >
                    <AuthAdmin />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/products"
                element={
                  <ProtectedRoute
                    permittedRoles={["administrator", "operator"]}
                  >
                    <ProductAdmin />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/markets"
                element={
                  <ProtectedRoute
                    permittedRoles={["administrator", "operator"]}
                  >
                    <MarketAdmin />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/trading"
                element={
                  <ProtectedRoute
                    permittedRoles={["administrator", "operator"]}
                  >
                    <TradingAdmin />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/marketdata/submitmarks"
                element={
                  <ProtectedRoute
                    permittedRoles={["api-manage-marks", "api-approve-marks"]}
                  >
                    <SubmitMarksAuthWrapper
                      marketsWithAttributes={marketsWithAttributes}
                      marketGroupMarkets={marketGroupMarkets}
                      products={products}
                      venues={venues}
                      termSchedules={termSchedules}
                      userAccountSubscriptionsByUserAccount={
                        userAccountSubscriptionsByUserAccount
                      }
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/marketdata/reviewmarks"
                element={
                  <ProtectedRoute permittedRoles={["api-approve-marks"]}>
                    <ReviewMarksAuthWrapper />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/marketdata/submittrades"
                element={
                  <ProtectedRoute
                    permittedRoles={["api-manage-trades", "api-approve-trades"]}
                  >
                    <SubmitTradesAuthWrapper
                      marketsWithAttributes={marketsWithAttributes}
                      marketGroupMarkets={marketGroupMarkets}
                      products={products}
                      venues={venues}
                      termSchedules={termSchedules}
                      userAccountSubscriptionsByUserAccount={
                        userAccountSubscriptionsByUserAccount
                      }
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/marketdata/submitexternaldata"
                element={
                  <ProtectedRoute permittedRoles={["api-manage-external-data"]}>
                    <SubmitExternalDataAuthWrapper
                      marketsWithAttributes={marketsWithAttributes}
                      marketGroupMarkets={marketGroupMarkets}
                      products={products}
                      venues={venues}
                      termSchedules={termSchedules}
                      userAccountSubscriptionsByUserAccount={
                        userAccountSubscriptionsByUserAccount
                      }
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/marketdata/reviewtrades"
                element={
                  <ProtectedRoute permittedRoles={["api-approve-trades"]}>
                    <ReviewTradesAuthWrapper />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/marketdata/"
                element={
                  <ProtectedRoute
                    permittedRoles={["api-query-marks", "api-query-trades"]}
                  >
                    <MarketDataAuthWrapper
                      marketsWithAttributes={marketsWithAttributes}
                      marketGroupMarkets={marketGroupMarkets}
                      products={products}
                      venues={venues}
                      termSchedules={termSchedules}
                      userAccountSubscriptionsByUserAccount={
                        userAccountSubscriptionsByUserAccount
                      }
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/historicalmarketdata/"
                element={
                  <ProtectedRoute permittedRoles={["api-query-marks"]}>
                    <HistoricalMarketDataAuthWrapper
                      marketsWithAttributes={marketsWithAttributes}
                      marketGroupMarkets={marketGroupMarkets}
                      products={products}
                      venues={venues}
                      termSchedules={termSchedules}
                      userAccountSubscriptionsByUserAccount={
                        userAccountSubscriptionsByUserAccount
                      }
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/historicaltrades/"
                element={
                  <ProtectedRoute permittedRoles={["api-query-trades"]}>
                    <HistoricalTradeDataAuthWrapper
                      marketsWithAttributes={marketsWithAttributes}
                      marketGroupMarkets={marketGroupMarkets}
                      products={products}
                      venues={venues}
                      termSchedules={termSchedules}
                      userAccountSubscriptionsByUserAccount={
                        userAccountSubscriptionsByUserAccount
                      }
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/albertaenergydaily/"
                element={
                  <ProtectedRoute
                    permittedRoles={[
                      "administrator",
                      "energy-daily-subscriber",
                    ]}
                  >
                    <AlbertaPowerReport
                      allowData={isDailyReportDataSubscriber}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboards/albertaenvironmental"
                element={
                  <DashboardContainer
                    fileName={`albertaEnvironmentalDashboard${theme}.html`}
                  />
                }
              />
              <Route
                path="/dashboards/albertaelectricity"
                element={
                  <DashboardContainer
                    fileName={`albertaElectricityDashboard${theme}.html`}
                  />
                }
              />
              <Route
                path="/trading"
                element={
                  //Stub
                  <ProtectedRoute permittedRoles={["administrator"]}>
                    <TradingComponent />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboards/albertaenvironmental/trades"
                element={
                  /*                   <ProtectedRoute permittedRoles={['api-query-trades']}> */
                  <AlbertaEnvironmentalTrades isSubscriber={isSubscriber} />
                  /*                   </ProtectedRoute> */
                }
              />
              <Route
                path="/dashboards/albertaenvironmental/issues"
                element={
                  <ProtectedRoute permittedRoles={["api-query-trades"]}>
                    <AlbertaEnvironmentalIssues />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboards/albertaenvironmental/statuschanges"
                element={
                  <ProtectedRoute permittedRoles={["api-query-trades"]}>
                    <AlbertaEnvironmentalStatusChanges />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboards/albertaenvironmental/registry"
                element={
                  /*                   <ProtectedRoute permittedRoles={['api-query-trades']}> */
                  <AlbertaEnvironmentalRegistry isSubscriber={isSubscriber} />
                  /*                   </ProtectedRoute> */
                }
              />

              <Route path="/maintenance" element={<Maintenance />} />
              <Route path="/not-found" element={<NotFound />} />
              <Route path="*" element={<Navigate replace to="/not-found" />} />
            </Routes>
          </div>

          {/* Footer with Ticker */}
          {queryMarks && isTickerRoute() && (
            <div className={`footer ${isFooterCollapsed ? "collapsed" : ""}`}>
              <button
                className={`footer-toggle ${
                  isFooterCollapsed ? "collapsed" : ""
                }`}
                onClick={() => setIsFooterCollapsed(!isFooterCollapsed)}
                aria-label={
                  isFooterCollapsed ? "Expand ticker" : "Collapse ticker"
                }
              >
                <FontAwesome name="chevron-up" className="fa" />
                <span>{isFooterCollapsed ? "Show" : "Hide"} Ticker</span>
              </button>
              <div className="footer-content">
                <Routes>
                  {[
                    "/",
                    "/marketdata/*",
                    "/historicalmarketdata/*",
                    "/dashboards/*",
                  ].map((path) => (
                    <Route
                      key={path}
                      path={path}
                      element={
                        <div className="ticker-container">
                          <MarketDataTicker userAccountId={userAccountId} />
                        </div>
                      }
                    />
                  ))}
                </Routes>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default App;
