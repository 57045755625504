import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import Table from "../../../common/components/table/table";
import { withTranslation } from "react-i18next";

class TermSchedulesTable extends Component {
  columns = [
    { path: "name", label: this.props.t("Name") },
    {
      path: "forwardReportingPeriodicity",
      label: this.props.t("ForwardReportingPeriodicity"),
    },
    {
      path: "active",
      label: this.props.t("Active"),
      content: (termSchedule) => (
        <FontAwesome name={termSchedule.active ? "check" : "times"} />
      ),
    },
  ];

  // constructor() {
  //     super();
  //     const user = products.getCurrentUser();
  //     if (user && user.isAdmin)
  //         this.columns.push(this.deleteColumn);
  // }

  render() {
    const { termSchedules, onSort, sortColumn, onRowClick } = this.props;
    return (
      <Table
        className="table-bold"
        columns={this.columns}
        sortColumn={sortColumn}
        onSort={onSort}
        data={termSchedules}
        onRowClick={onRowClick}
        valueProperty="termScheduleId"
      />
    );
  }
}

export default withTranslation(["markets"])(TermSchedulesTable);
