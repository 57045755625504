import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  getWindLongTermForecastByMarketDate,
  getSolarLongTermForecastByMarketDate,
  getPoolPriceByMarketDate,
  getDailyPoolPriceAverages,
  getInternalLoadByMarketDate,
  getDailyInternalLoadAverages,
  getSummary,
  //getDailyOutageReport,
  getMonthlyOutageForecastReport,
  getCurrentGenerationByMarketDateFuelType,
} from "../../common/services/marketdata/aesoService";
import {
  getSummaryReport,
  getCalSettlementsByMarketDateRange,
  getSpotSettlementsByMarketDateRange,
} from "../../common/services/marketdata/ngxSettlementService";
import {
  getRateOfLastResortByDateRange,
  getRegulatedRateOptionByDateRange,
} from "../../common/services/marketdata/enmaxRateService";
import { getTcDailyStorage } from "../../common/services/marketdata/tcGasSummaryService";
import { getEiaDailyStorage } from "../../common/services/marketdata/eiaGasSummaryService";
import { getMarkets } from "../../common/services/markets/marketService";
import AesoSummaryTable from "./aesoSummaryTable";
import AbStorageSummaryTable from "./abStorageSummaryTable";
import HourlyForecastLineGraph from "./hourlyForecastLineGraph";
import DailyForecastBarGraph from "./dailyForecastBarGraph";
import HourlyElectricityPricingAndLoadGraph from "./hourlyElectricityPricingAndLoadGraph";
import DailyElectricityPricingAndLoadGraph from "./dailyElectricityPricingAndLoadGraph";
import DailyElectricityPricingAndLoadDeltaGraph from "./dailyElectricityPricingAndLoadDeltaGraph";
import YearlyElectricityPricingAndLoadGraph from "./yearlyElectricityPricingAndLoadGraph";
import SettlementsTable from "./settlementsTable";
import HistoricalSettlementsGraph from "./historicalSettlementsGraph";
import MonthlyOutageForecastBarGraph from "./monthlyOutageForecastBarGraph";
import NaturalGasPoolPriceLineGraph from "./naturalGasPoolPriceLineGraph";
import HistoricalHeatRateLineGraph from "./historicalHeatRateLineGraph";
import AesoYearlyAveragePoolPriceGraph from "./aesoYearlyAveragePoolPriceGraph";
import DailyStorageLineGraph from "./dailyStorageLineGraph";
import DailyPoolPriceLineGraph from "./dailyPoolPriceLineGraph";
import GenerationByFuelTypeGraph from "./generationByFuelTypeGraph";
import AesoPoolPriceVsEnmaxRetailLineGraph from "./aesoPoolPriceVsEnmaxRetailLineGraph";
import SettlementsExport from "./settlementsExport/settlementsExport";
import Input from "../../common/components/form/input";
import FontAwesome from "react-fontawesome";
import Modal from "react-bootstrap/Modal";

//import Loading from "../../common/components/loading/loading";

class AlbertaPowerReport extends Component {
  state = {
    longTermWindForecast: [],
    longTermSolarForecast: [],
    poolPriceDailyAverages: [],
    powerSettles: [],
    gasSettles: [],
    calPowerSettles: [],
    calGasSettles: [],
    spotGasSettles: [],
    poolPrices: [],
    internalLoad: [],
    internalLoadDailyAverages: [],
    monthlyOutageForecast: [],
    aesoSummaryData: [],
    abDailyStorage: [],
    usDailyStorage: [],
    abStorageSummary: [],
    currentGenerationByFuelType: [],
    regulatedRateOption: [],
    rateOfLastResort: [],
    priorPriceInternalLoadData: [],
    historicalPriceInternalLoadDataMerged: [],
    aesoHistoricalLineChartData: [],
    marketDate: "",
    effectivetDate: "",
    maxDate: "",
    minDate: "2024-01-01", //Stub
    //loading indicators:
    isLoading: true,
    loadingLongTermWindForecast: true,
    loadingLongTermSolarForecast: true,
    loadingPoolPriceDailyAverages: true,
    loadingPowerSettles: true,
    loadingGasSettles: true,
    loadingCalPowerSettles: true,
    loadingCalGasSettles: true,
    loadingSpotGasSettles: true,
    loadingPoolPrices: true,
    loadingInternalLoad: true,
    loadingInternalLoadDailyAverages: true,
    loadingMonthlyOutageForecast: true,
    loadingAesoSummaryData: true,
    loadingAbDailyStorage: true,
    loadingUsDailyStorage: true,
    loadingAbStorageSummary: true,
    loadingCurrentGenerationByFuelType: true,
    loadingRegulatedRateOption: true,
    loadingRateOfLastResort: true,
    loadingPriorPriceInternalLoadData: true,
    loadingHistoricalPriceInternalLoadDataMerged: true,
    loadingAesoHistoricalLineChartData: true,
    expandedTiles: {},
    logoBase64: null,
    showSettlementsModal: false,
  };

  async componentDidMount() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);

    const marketDate = new Date(currentDate).toLocaleDateString("fr-CA");

    const minDate = new Date(currentDate);
    minDate.setDate(minDate.getDate() - 30);
    const minDateText = new Date(minDate).toLocaleDateString("fr-CA");

    this.setState({
      marketDate: marketDate,
      maxDate: marketDate,
      minDate: minDateText,
      isLoading: true,
    });
    await this.loadData(marketDate);
  }

  async loadData(marketDate) {
    // //Override market date if it is current and make it prior day.
    // const effectiveDate =
    //   marketDate === new Date().toLocaleDateString("fr-CA")
    //     ? new Date(
    //         new Date(marketDate).toLocaleDateString("fr-CA", {
    //           timeZone: "UTC",
    //         })
    //       ).toLocaleDateString("fr-CA")
    //     : marketDate;

    //Override removed, Market date will just be defautled to prior day going forward.
    const effectiveDate = marketDate;

    this.setState({ effectiveDate: effectiveDate });

    const markets = await getMarkets();
    const aesoMarket = markets.find((x) => x.name === "AESO");
    const albertaGasMarket = markets.find((x) => x.name === "AECO-C");

    await Promise.all([
      this.loadAbGasStorageData(effectiveDate),
      this.loadCurrentGeneration(effectiveDate),
      this.loadAesoSummary(effectiveDate),
      this.loadCalSettles(effectiveDate, aesoMarket, albertaGasMarket),
      this.loadSpotGasSettles(effectiveDate, albertaGasMarket),
      this.loadCurrentSettles(effectiveDate, aesoMarket, albertaGasMarket),
      this.loadAesoDailyAveragesData(effectiveDate),
      this.getAesoPriceAndLoad(effectiveDate),
      this.loadUsGasStorage(effectiveDate),
      this.loadEnmaxRates(effectiveDate),
      this.loadMonthlyOutages(effectiveDate),
    ]);

    this.loadLogoImage();

    this.setState({ isLoading: false });
  }

  loadLogoImage = async () => {
    try {
      const imagePath = require("../../config/images/Neutral Markets Logo.png");
      const response = await fetch(imagePath);
      const blob = await response.blob();
      const base64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
      this.setState({ logoBase64: base64 });
    } catch (error) {
      //console.error("Error loading logo:", error);
    }
  };

  async loadAbGasStorageData(effectiveDate) {
    //Set start of 5 years ago param
    const fiveYearAgoStart = new Date(
      new Date(effectiveDate).getFullYear() - 5,
      0,
      1
    );

    const abDailyStorage = await getTcDailyStorage(
      fiveYearAgoStart.toLocaleDateString("fr-CA", { timeZone: "UTC" }),
      effectiveDate
    );

    const abStorageSummary = await this.processStorageSummary(
      abDailyStorage,
      effectiveDate
    );

    this.setState({
      abStorageSummary: abStorageSummary,
      abDailyStorage: abDailyStorage,
      loadingAbDailyStorage: false,
      loadingAbStorageSummary: false,
    });
  }

  async loadAesoSummary(effectiveDate) {
    //set Prior year param
    const priorYear = new Date(effectiveDate);
    priorYear.setFullYear(priorYear.getFullYear() - 1);

    //Set the Forecast Date - This is so we get actuals for hte current date and the forecasts are forward looking.
    const forecastDate = new Date(effectiveDate);
    forecastDate.setDate(forecastDate.getDate() + 1);
    forecastDate.setMinutes(
      forecastDate.getMinutes() + forecastDate.getTimezoneOffset()
    );

    const forecastDateParam = forecastDate.toLocaleDateString("fr-CA");

    const [
      longTermWindForecast,
      longTermSolarForecast,
      aesoSummaryCurrent,
      aesoSummaryPriorYear,
    ] = await Promise.all([
      getWindLongTermForecastByMarketDate(forecastDateParam),
      getSolarLongTermForecastByMarketDate(forecastDateParam),
      getSummary(effectiveDate),
      getSummary(priorYear.toLocaleDateString("fr-CA", { timeZone: "UTC" })),
    ]);

    const aesoSummaryData = await this.generateAesoSummary(
      aesoSummaryCurrent,
      aesoSummaryPriorYear,
      longTermWindForecast,
      longTermSolarForecast,
      effectiveDate
    );

    this.setState({
      longTermWindForecast: longTermWindForecast,
      longTermSolarForecast: longTermSolarForecast,
      aesoSummaryData: aesoSummaryData,
      loadingLongTermWindForecast: false,
      loadingLongTermSolarForecast: false,
      loadingAesoSummaryData: false,
    });
  }

  async loadAesoDailyAveragesData(effectiveDate) {
    //Set start of 5 years ago param
    const fiveYearAgoStart = new Date(
      new Date(effectiveDate).getFullYear() - 5,
      0,
      1
    );

    const [poolPriceDailyAverages, internalLoadDailyAverages] =
      await Promise.all([
        getDailyPoolPriceAverages(effectiveDate),
        getDailyInternalLoadAverages(effectiveDate),
      ]);

    const aesoHistoricalLineChartData = poolPriceDailyAverages.filter(
      (x) => new Date(x.marketDate) >= fiveYearAgoStart
    );

    this.setState({
      poolPriceDailyAverages: poolPriceDailyAverages,
      internalLoadDailyAverages: internalLoadDailyAverages,
      aesoHistoricalLineChartData: aesoHistoricalLineChartData,
      //loading indicators
      loadingPoolPriceDailyAverages: false,
      loadingInternalLoadDailyAverages: false,
      loadingAesoHistoricalLineChartData: false,
    });
  }

  async getAesoPriceAndLoad(effectiveDate) {
    var fromDate = new Date(effectiveDate);
    fromDate.setDate(fromDate.getDate() - 30);

    var toDate = new Date(effectiveDate);
    toDate.setDate(toDate.getDate() - 0);

    const fromDateParam = fromDate.toLocaleDateString("fr-CA", {
      timeZone: "UTC",
    });
    const toDateParam = toDate.toLocaleDateString("fr-CA", { timeZone: "UTC" });

    //set Prior year param
    const priorYear = new Date(effectiveDate);
    priorYear.setFullYear(priorYear.getFullYear() - 1);

    const [poolPrices, internalLoad] = await Promise.all([
      getPoolPriceByMarketDate(fromDateParam, toDateParam),
      getInternalLoadByMarketDate(fromDateParam, toDateParam),
    ]);

    const poolPriceInternalLoadDataMerged =
      await this.mergePoolPriceInternalLoad(poolPrices, internalLoad);

    const priorPriceInternalLoadData = poolPriceInternalLoadDataMerged.filter(
      (x) =>
        new Date(x.beginDateTimeMpt).toLocaleDateString("fr-CA", {
          timeZone: "UTC",
        }) === effectiveDate
    );

    const historicalPriceInternalLoadDataMerged =
      poolPriceInternalLoadDataMerged.filter(
        (x) =>
          new Date(x.beginDateTimeMpt).toLocaleDateString("fr-CA", {
            timeZone: "UTC",
          }) <= effectiveDate
      );

    this.setState({
      poolPrices: poolPrices,
      internalLoad: internalLoad,
      priorPriceInternalLoadData: priorPriceInternalLoadData,
      historicalPriceInternalLoadDataMerged:
        historicalPriceInternalLoadDataMerged,
      //loading indicators
      loadingPoolPrices: false,
      loadingInternalLoad: false,
      loadingPriorPriceInternalLoadData: false,
      loadingHistoricalPriceInternalLoadDataMerged: false,
    });
  }

  async loadUsGasStorage(effectiveDate) {
    var fromDate = new Date(effectiveDate);
    fromDate.setDate(fromDate.getDate() - 30);

    var toDate = new Date(effectiveDate);
    toDate.setDate(toDate.getDate() - 0);

    //set Prior year param
    const priorYear = new Date(effectiveDate);
    priorYear.setFullYear(priorYear.getFullYear() - 1);

    //Set start of 5 years ago param
    const fiveYearAgoStart = new Date(
      new Date(effectiveDate).getFullYear() - 5,
      0,
      1
    );

    const usDailyStorage = await getEiaDailyStorage(
      fiveYearAgoStart.toLocaleDateString("fr-CA", { timeZone: "UTC" }),
      effectiveDate
    );

    this.setState({
      usDailyStorage: usDailyStorage,
      //loading indicators
      loadingUsDailyStorage: false,
    });
  }

  async loadMonthlyOutages(effectiveDate) {
    const monthlyOutageForecast = await getMonthlyOutageForecastReport(
      effectiveDate
    );

    this.setState({
      monthlyOutageForecast: monthlyOutageForecast,
      //loading indicators
      loadingMonthlyOutageForecast: false,
    });
  }

  async loadEnmaxRates(effectiveDate) {
    var fromDate = new Date(effectiveDate);
    fromDate.setDate(fromDate.getDate() - 30);

    var toDate = new Date(effectiveDate);
    toDate.setDate(toDate.getDate() - 0);

    //set Prior year param
    const priorYear = new Date(effectiveDate);
    priorYear.setFullYear(priorYear.getFullYear() - 1);

    //Set start of 10 years ago param
    const tenYearAgoStart = new Date(
      new Date(effectiveDate).getFullYear() - 10,
      0,
      1
    );

    const [regulatedRateOption, rateOfLastResort] = await Promise.all([
      getRegulatedRateOptionByDateRange(
        tenYearAgoStart.toLocaleDateString("fr-CA", { timeZone: "UTC" }),
        effectiveDate
      ),
      getRateOfLastResortByDateRange(
        tenYearAgoStart.toLocaleDateString("fr-CA", { timeZone: "UTC" }),
        effectiveDate
      ),
    ]);

    this.setState({
      regulatedRateOption: regulatedRateOption,
      rateOfLastResort: rateOfLastResort,
      //loading indicators
      loadingRegulatedRateOption: false,
      loadingRateOfLastResort: false,
    });
  }

  async loadCalSettles(effectiveDate, aesoMarket, albertaGasMarket) {
    //Set Cal Settles Paramters
    const calSettlesFromDate = new Date(effectiveDate);
    calSettlesFromDate.setFullYear(calSettlesFromDate.getFullYear() - 5);

    const fromTerm = new Date(effectiveDate).getFullYear();
    const toTerm = fromTerm + 4;

    //Run in Parallel
    const [calPowerSettlesRaw, calGasSettlesRaw] = await Promise.all([
      getCalSettlementsByMarketDateRange(
        aesoMarket.marketId,
        calSettlesFromDate.toLocaleDateString("fr-CA"),
        effectiveDate
      ),
      getCalSettlementsByMarketDateRange(
        albertaGasMarket.marketId,
        calSettlesFromDate.toLocaleDateString("fr-CA"),
        effectiveDate
      ),
    ]);

    const calPowerSettles = calPowerSettlesRaw.filter(
      (x) => x.termName >= "Cal " + fromTerm && x.termName <= "Cal " + toTerm
    );

    const calGasSettles = calGasSettlesRaw.filter(
      (x) => x.termName >= "Cal " + fromTerm && x.termName <= "Cal " + toTerm
    );

    this.setState({
      calPowerSettles: calPowerSettles,
      calGasSettles: calGasSettles,
      //loading indicators
      loadingCalPowerSettles: false,
      loadingCalGasSettles: false,
    });
  }

  async loadSpotGasSettles(effectiveDate, albertaGasMarket) {
    //Force these longer running queries to run in parallel.
    const spotGasSettles = await getSpotSettlementsByMarketDateRange(
      albertaGasMarket.marketId,
      new Date("2017-01-01").toLocaleDateString("fr-CA"), //Hard code this for now
      effectiveDate
    );

    this.setState({
      spotGasSettles: spotGasSettles,
      //loading indicators
      loadingSpotGasSettles: false,
    });
  }

  async loadCurrentSettles(effectiveDate, aesoMarket, albertaGasMarket) {
    //Force these longer running queries to run in parallel.
    const [powerSettlesRaw, gasSettlesRaw] = await Promise.all([
      getSummaryReport(aesoMarket.marketId, effectiveDate),
      getSummaryReport(albertaGasMarket.marketId, effectiveDate),
    ]);

    const powerSettlesWithHeatRates = powerSettlesRaw.map((x) => ({
      ...x,
      heatRate:
        x.current /
        gasSettlesRaw.find(
          (y) => y.beginDate === x.beginDate && y.endDate === x.endDate
        )?.current,
    }));

    const powerSettles = await this.sortAndSliceSettles(
      powerSettlesWithHeatRates
    );
    const gasSettles = await this.sortAndSliceSettles(gasSettlesRaw);

    this.setState({
      powerSettles: powerSettles,
      gasSettles: gasSettles,
      //loading indicators
      loadingPowerSettles: false,
      loadingGasSettles: false,
    });
  }

  async loadCurrentGeneration(effectiveDate) {
    const currentGenerationByFuelType =
      await getCurrentGenerationByMarketDateFuelType(effectiveDate);

    this.setState({
      currentGenerationByFuelType: currentGenerationByFuelType,
      loadingCurrentGenerationByFuelType: false,
    });
  }

  async sortAndSliceSettles(settles) {
    const calSettles = settles.filter((x) => x.termName.indexOf("Cal") >= 0);
    calSettles.sort((a, b) => a.termName - b.termName);

    const monthlySettles = settles.filter(
      (x) =>
        x.termName.indexOf("Cal") < 0 &&
        x.termName !== "Prior Day" &&
        x.termName !== "Same Day" &&
        x.termName !== "Day Ahead" &&
        x.termName.indexOf("D+") < 0
    );
    monthlySettles.sort(
      (a, b) => new Date(a.beginDate) - new Date(b.beginDate)
    );

    const dailySettles = settles.filter(
      (x) =>
        x.termName === "Prior Day" ||
        x.termName === "Same Day" ||
        x.termName === "Day Ahead" /*  || x.termName.indexOf('D+') >= 0 */
    );
    dailySettles.sort((a, b) => new Date(a.beginDate) - new Date(b.beginDate));

    const processedSettles = [
      ...dailySettles,
      ...monthlySettles.slice(0, 12),
      ...calSettles.slice(0, 5),
    ];

    return processedSettles;
  }

  async mergePoolPriceInternalLoad(poolPrices, internalLoad) {
    function sameDay(date1, date2) {
      const d1 = new Date(date1);
      const d2 = new Date(date2);
      return (
        d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getFullYear() === d2.getFullYear()
      );
    }

    // Merge the two datasets based on begin_datetime_mpt
    const poolPriceInternalLoadDataMerged = poolPrices.map((poolItem) => {
      const matchingInternalLoadItem = internalLoad.find(
        (internalItem) =>
          sameDay(internalItem.marketDate, poolItem.marketDate) &&
          internalItem.beginDateTimeMpt === poolItem.beginDateTimeMpt
      );
      return {
        beginDateTimeMpt: poolItem.beginDateTimeMpt,
        beginDateTimeUtc: poolItem.beginDateTimeUtc,
        albertaInternalLoad: matchingInternalLoadItem
          ? matchingInternalLoadItem.albertaInternalLoad
          : null,
        forecastAlbertaInternalLoad: matchingInternalLoadItem
          ? matchingInternalLoadItem.forecastAlbertaInternalLoad
          : null,
        poolPrice: poolItem.poolPrice,
        forecastPoolPrice: poolItem.forecastPoolPrice,
        rolling30DayAvg: poolItem.rolling30DayAvg,
        marketDate: poolItem.marketDate,
      };
    });

    return poolPriceInternalLoadDataMerged;
  }

  async processStorageSummary(abDailyStorage, effectiveDate) {
    const currentDate = new Date(effectiveDate);

    const priorDate = new Date(effectiveDate);
    priorDate.setDate(priorDate.getDate() - 1);

    const priorWeek = new Date(effectiveDate);
    priorWeek.setDate(priorWeek.getDate() - 7);

    const priorYear = new Date(effectiveDate);
    priorYear.setFullYear(priorYear.getFullYear() - 1);

    const abStorageSummary = abDailyStorage
      .filter(
        (x) =>
          new Date(x.gasDay).toLocaleDateString("fr-CA", {
            timeZone: "UTC",
          }) ===
            new Date(currentDate).toLocaleDateString("fr-CA", {
              timeZone: "UTC",
            }) ||
          new Date(x.gasDay).toLocaleDateString("fr-CA", {
            timeZone: "UTC",
          }) ===
            new Date(priorDate).toLocaleDateString("fr-CA", {
              timeZone: "UTC",
            }) ||
          new Date(x.gasDay).toLocaleDateString("fr-CA", {
            timeZone: "UTC",
          }) ===
            new Date(priorWeek).toLocaleDateString("fr-CA", {
              timeZone: "UTC",
            }) ||
          new Date(x.gasDay).toLocaleDateString("fr-CA", {
            timeZone: "UTC",
          }) ===
            new Date(priorYear).toLocaleDateString("fr-CA", {
              timeZone: "UTC",
            })
      )
      .map((x) => ({
        ...x,
        percentFull: (100 * x.inventory) / x.capacity,
        item:
          new Date(x.gasDay).toLocaleDateString("fr-CA", {
            timeZone: "UTC",
          }) ===
          new Date(currentDate).toLocaleDateString("fr-CA", {
            timeZone: "UTC",
          })
            ? "Current Day"
            : new Date(x.gasDay).toLocaleDateString("fr-CA", {
                timeZone: "UTC",
              }) ===
              new Date(priorDate).toLocaleDateString("fr-CA", {
                timeZone: "UTC",
              })
            ? "Prior Day"
            : new Date(x.gasDay).toLocaleDateString("fr-CA", {
                timeZone: "UTC",
              }) ===
              new Date(priorWeek).toLocaleDateString("fr-CA", {
                timeZone: "UTC",
              })
            ? "Prior Week"
            : new Date(x.gasDay).toLocaleDateString("fr-CA", {
                timeZone: "UTC",
              }) ===
              new Date(priorYear).toLocaleDateString("fr-CA", {
                timeZone: "UTC",
              })
            ? "Prior Year"
            : "",
      }));

    abStorageSummary.sort((a, b) => new Date(b.gasDay) - new Date(a.gasDay));

    return abStorageSummary;
  }

  async generateAesoSummary(
    aesoSummaryCurrent,
    aesoSummaryPriorYear,
    longTermWindForecast,
    longTermSolarForecast,
    effectiveDate
  ) {
    const aesoSummaryCurrentExtended = aesoSummaryCurrent.map((data) => ({
      sortOrder:
        data.item === "Summary"
          ? 0
          : data.item === "MTD Avg"
          ? 1
          : data.item === "YTD Avg"
          ? 2
          : 999,
      ...data,
      maxPriceHourly: data.item === "Summary" ? data.maxPriceHourly : null,
      pricePrecision: 2,
      currencySymbol: "$",
      uomSymbol: "",
    }));

    const aesoLoadExtended = aesoSummaryCurrent
      .filter((x) => x.item === "Summary")
      .map((data) => ({
        sortOrder: data.item === "Summary" ? 3 : 999,
        item: "Avg Load",
        beginDateTime: data.beginDateTime,
        weightedAverage: null,
        weightedAverageYesterday: null,
        maxPriceHourly: null,
        numericAverage: data.averageLoad,
        numericAverageYesterday: data.averageLoadYesterday,
        onPeak7x16Average: data.onPeak7x16AverageLoad,
        onPeak7x16AverageYesterday: data.onPeak7x16AverageLoadYesterday,
        offPeak7x8Average: data.offPeak7x8AverageLoad,
        offPeak7x8AverageYesterday: data.offPeak7x8AverageLoadYesterday,
        pricePrecision: 0,
        currencySymbol: "",
        uomSymbol: "MW",
      }));

    const aesoSummaryPriorYearExtended = aesoSummaryPriorYear
      .filter((x) => x.item !== "MTD Avg")
      .map((data) => ({
        sortOrder:
          data.item === "YTD Avg" ? 4 : data.item === "Summary" ? 5 : 999,
        ...data,
        item:
          data.item === "Summary"
            ? "Prior Year"
            : data.item === "YTD Avg"
            ? "YTD Prior Year"
            : "Unknown",
        maxPriceHourly: data.item === "Summary" ? data.maxPriceHourly : "",
        pricePrecision: 2,
        currencySymbol: "$",
        uomSymbol: "",
      }));

    const priorDate = new Date(effectiveDate);
    priorDate.setDate(priorDate.getDate() - 1);

    const windToday = longTermWindForecast.filter((x) => {
      const adjustedForecastDate = new Date(
        new Date(x.forecastTransactionDate).getTime() - 3600000
      );

      return (
        adjustedForecastDate.toLocaleDateString("fr-CA", {
          timeZone: "UTC",
        }) === effectiveDate
      );
    });

    const windTodayOnPeak = windToday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getUTCHours() >= 8 &&
        new Date(x.forecastTransactionDate).getUTCHours() <= 23
    );
    const windTodayOffPeak = windToday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getUTCHours() < 8 ||
        new Date(x.forecastTransactionDate).getUTCHours() > 23
    );

    const windYesterday = longTermWindForecast.filter((x) => {
      const adjustedForecastDate = new Date(
        new Date(x.forecastTransactionDate).getTime() - 3600000
      );

      return (
        adjustedForecastDate.toLocaleDateString("fr-CA", {
          timeZone: "UTC",
        }) ===
        priorDate.toLocaleDateString("fr-CA", {
          timeZone: "UTC",
        })
      );
    });

    const windYesterdayOnPeak = windYesterday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getUTCHours() >= 8 &&
        new Date(x.forecastTransactionDate).getUTCHours() <= 23
    );
    const windYesterdayOffPeak = windYesterday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getUTCHours() < 8 ||
        new Date(x.forecastTransactionDate).getUTCHours() > 23
    );

    const currentDate = new Date(effectiveDate);
    currentDate.setMinutes(
      currentDate.getMinutes() + currentDate.getTimezoneOffset()
    );

    const wind = [
      {
        sortOrder: 6,
        item: "Wind",
        beginDateTime: currentDate,
        weightedAverage: null,
        weightedAverageYesterday: null,
        maxPriceHourly: null,
        numericAverage:
          windToday.length > 0
            ? windToday.reduce((a, b) => ({ actual: a.actual + b.actual }))
                .actual / windToday.length
            : null,
        numericAverageYesterday:
          windYesterday.length > 0
            ? windYesterday.reduce((a, b) => ({ actual: a.actual + b.actual }))
                .actual / windYesterday.length
            : null,
        onPeak7x16Average:
          windTodayOnPeak.length > 0
            ? windTodayOnPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / windTodayOnPeak.length
            : null,
        onPeak7x16AverageYesterday:
          windYesterdayOnPeak.length > 0
            ? windYesterdayOnPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / windYesterdayOnPeak.length
            : null,
        offPeak7x8Average:
          windTodayOffPeak.length > 0
            ? windTodayOffPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / windTodayOffPeak.length
            : null,
        offPeak7x8AverageYesterday:
          windYesterdayOffPeak.length > 0
            ? windYesterdayOffPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / windYesterdayOffPeak.length
            : null,
        pricePrecision: 0,
        currencySymbol: "",
        uomSymbol: "MW",
      },
    ];

    const solarToday = longTermSolarForecast.filter((x) => {
      const adjustedForecastDate = new Date(
        new Date(x.forecastTransactionDate).getTime() - 3600000
      );

      return (
        adjustedForecastDate.toLocaleDateString("fr-CA", {
          timeZone: "UTC",
        }) === effectiveDate
      );
    });

    const solarTodayOnPeak = solarToday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getUTCHours() >= 8 &&
        new Date(x.forecastTransactionDate).getUTCHours() <= 23
    );
    const solarTodayOffPeak = solarToday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getUTCHours() < 8 ||
        new Date(x.forecastTransactionDate).getUTCHours() > 23
    );

    const solarYesterday = longTermSolarForecast.filter((x) => {
      const adjustedForecastDate = new Date(
        new Date(x.forecastTransactionDate).getTime() - 3600000
      );

      return (
        adjustedForecastDate.toLocaleDateString("fr-CA", {
          timeZone: "UTC",
        }) ===
        priorDate.toLocaleDateString("fr-CA", {
          timeZone: "UTC",
        })
      );
    });

    const solarYesterdayOnPeak = solarYesterday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getUTCHours() >= 8 &&
        new Date(x.forecastTransactionDate).getUTCHours() <= 23
    );
    const solarYesterdayOffPeak = solarYesterday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getUTCHours() < 8 ||
        new Date(x.forecastTransactionDate).getUTCHours() > 23
    );

    const solar = [
      {
        sortOrder: 7,
        item: "Solar",
        beginDateTime: currentDate,
        weightedAverage: null,
        weightedAverageYesterday: null,
        maxPriceHourly: null,
        numericAverage:
          solarToday.length > 0
            ? solarToday.reduce((a, b) => ({ actual: a.actual + b.actual }))
                .actual / solarToday.length
            : null,
        numericAverageYesterday:
          solarYesterday.length > 0
            ? solarYesterday.reduce((a, b) => ({ actual: a.actual + b.actual }))
                .actual / solarYesterday.length
            : null,
        onPeak7x16Average:
          solarTodayOnPeak.length > 0
            ? solarTodayOnPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / solarTodayOnPeak.length
            : null,
        onPeak7x16AverageYesterday:
          solarYesterdayOnPeak.length > 0
            ? solarYesterdayOnPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / solarYesterdayOnPeak.length
            : null,
        offPeak7x8Average:
          solarTodayOffPeak.length > 0
            ? solarTodayOffPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / solarTodayOffPeak.length
            : null,
        offPeak7x8AverageYesterday:
          solarYesterdayOffPeak.length > 0
            ? solarYesterdayOffPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / solarYesterdayOffPeak.length
            : null,
        pricePrecision: 0,
        currencySymbol: "",
        uomSymbol: "MW",
      },
    ];

    const aesoSummary = [
      ...aesoSummaryCurrentExtended,
      ...aesoLoadExtended,
      ...aesoSummaryPriorYearExtended,
      ...wind,
      ...solar,
    ];

    return aesoSummary;
  }

  handleDateChange = (marketDate) => {
    this.setState({
      marketDate: marketDate,
      reloading: true,
      loadingLongTermWindForecast: true,
      loadingLongTermSolarForecast: true,
      loadingPoolPriceDailyAverages: true,
      loadingPowerSettles: true,
      loadingGasSettles: true,
      loadingCalPowerSettles: true,
      loadingCalGasSettles: true,
      loadingSpotGasSettles: true,
      loadingPoolPrices: true,
      loadingInternalLoad: true,
      loadingInternalLoadDailyAverages: true,
      loadingMonthlyOutageForecast: true,
      loadingAesoSummaryData: true,
      loadingAbDailyStorage: true,
      loadingUsDailyStorage: true,
      loadingAbStorageSummary: true,
      loadingCurrentGenerationByFuelType: true,
      loadingRegulatedRateOption: true,
      loadingRateOfLastResort: true,
      loadingPriorPriceInternalLoadData: true,
      loadingHistoricalPriceInternalLoadDataMerged: true,
      loadingAesoHistoricalLineChartData: true,
    });

    (async () => {
      await this.loadData(marketDate);
    })();
  };

  async print() {
    const { logoBase64 } = this.state;

    // 1. Create the iframe
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    // 2. Get the iframe's document
    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

    // 3. Write the initial document content to the iframe
    iframeDoc.open();
    iframeDoc.write(
      '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">'
    );
    iframeDoc.write(
      '<html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en"><head><title></title>'
    );

    // Embed the screen's stylesheet
    const screenStylesheet = document.querySelector("style");
    if (screenStylesheet) {
      iframeDoc.write('<style type="text/css">');
      iframeDoc.write(screenStylesheet.innerHTML);
      iframeDoc.write("</style>");
    }

    iframeDoc.write('<style type="text/css">');
    iframeDoc.write(`
        @media print {
          @page {
            margin: 0;
            padding: 0.5cm;
            size: landscape;
/*            @bottom-right {
              content: "Page " counter(pageNumber);
            }*/
          }
           

          body {
            margin: 0;
            font-size: 6pt !important;
            color: black;
          }

          h1 {
            font-size: 18pt !important;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
          }

          h5 {
            font-size: 12pt !important;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
          }

          h6 {
            font-size: 8pt !important;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
          }

          /* 1. Core Grid Reset and Flexbox Enablement */
          .row {
            display: flex !important;
            flex-wrap: wrap !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
            page-break-inside: avoid;
            break-inside: avoid;
          }

          /* 2. General Column Adjustments */
          [class*="col-"] {
            float: none !important;
            display: block !important;
            box-sizing: border-box !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            word-break: break-word !important;
          }

          /* 3. Specific Column Widths */
          .col-md-12 {
            width: 100% !important;
          }

          .col-md-8 {
            width: 66.666666% !important;
          }

          .col-md-6 {
            width: 50% !important;
          }

          .col-md-4 {
            width: 33.333333% !important;
          }

          .col-md-2 {
            width: 16.666666% !important;
            min-width: 16.666666% !important;
            max-width: 16.666666% !important;
          }

          /* 4. Table and graph adjustments */
          table {
            font-size: 6pt !important;
            margin: 0px;
            width: 100%;
            border-style: solid;
            border-width: 1px;
          }

          thead tr {
            border-style: solid;
            border-width: 1px;
          }

          th,
          td {
            font-size: 6pt !important;
            padding: 0px !important;
          }

          tbody tr:nth-child(odd) {
            background-color: white;
          }

          tbody tr:nth-child(even) {
            background-color: whitesmoke;
          }

          .tile {
            border-style: solid;
            border-size: 3px;
            border-radius: 10px;
            border-color: white;
            background: whitesmoke;
          }

          .tile > * {
            transform-origin: top left !important;
          }

          /* 5. Hide elements that shouldn't be printed */
          .no-print {
            display: none !important;
          }

          img {
            display: block !important;
            width: auto !important;
            height: auto !important;
            max-width: 100% !important;
            object-fit: contain !important;
          }
        }
    `);

    iframeDoc.write("</style>");

    try {
      // Get the content and modify
      const contentValue = document.getElementById(
        "alberta-energy-daily-report-content"
      ).innerHTML;

      //Add the logo
      const modifiedContent = contentValue.replace(
        /<img[^>]*id="logo"[^>]*>/,
        `<img src="${logoBase64}" alt="" height="40" />`
      );

      // Write to the iframe
      iframeDoc.write("</head><body><center>");
      iframeDoc.write(modifiedContent);
      iframeDoc.write("</center></body></html>");

      // Finish writing and print
      iframeDoc.close();
      iframe.contentWindow.focus();

      // Add a small delay before printing - this is needed because the logo isn't always loaded in time.
      setTimeout(() => {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
      }, 100);
    } catch (error) {
      console.error("Error during printing:", error);
      // Handle the error gracefully (e.g., display an error message)
    } finally {
      // Cleanup iframe (use a more robust method as shown previously)
      const removeIframe = () => {
        document.body.removeChild(iframe);
      };

      if ("onafterprint" in window) {
        window.addEventListener("afterprint", removeIframe, { once: true });
      } else {
        setTimeout(removeIframe, 500);
      }
    }
  }

  // ADD THIS FUNCTION:  Handles the expand/collapse toggle
  handleTileExpand = (tileId) => {
    this.setState((prevState) => ({
      expandedTiles: {
        ...prevState.expandedTiles,
        [tileId]: !prevState.expandedTiles[tileId],
      },
    }));
  };

  handleCloseSettlementsModal = () =>
    this.setState({ showSettlementsModal: false });

  handleShowSettlementsModal = () =>
    this.setState({ showSettlementsModal: true });

  render() {
    const {
      longTermWindForecast,
      longTermSolarForecast,
      powerSettles,
      gasSettles,
      calPowerSettles,
      calGasSettles,
      spotGasSettles,
      poolPriceDailyAverages,
      internalLoadDailyAverages,
      aesoSummaryData,
      monthlyOutageForecast,
      abDailyStorage,
      usDailyStorage,
      abStorageSummary,
      currentGenerationByFuelType,
      regulatedRateOption,
      rateOfLastResort,
      priorPriceInternalLoadData,
      historicalPriceInternalLoadDataMerged,
      aesoHistoricalLineChartData,
      marketDate,
      effectiveDate,
      maxDate,
      minDate,
      //loading indicators
      isLoading,
      loadingLongTermWindForecast,
      loadingLongTermSolarForecast,
      loadingPoolPriceDailyAverages,
      loadingPowerSettles,
      loadingGasSettles,
      loadingCalPowerSettles,
      loadingCalGasSettles,
      //loadingSpotGasSettles,
      //loadingPoolPrices,
      //loadingInternalLoad,
      loadingInternalLoadDailyAverages,
      loadingMonthlyOutageForecast,
      loadingAesoSummaryData,
      loadingAbDailyStorage,
      loadingUsDailyStorage,
      loadingAbStorageSummary,
      loadingCurrentGenerationByFuelType,
      loadingRegulatedRateOption,
      loadingRateOfLastResort,
      loadingPriorPriceInternalLoadData,
      loadingHistoricalPriceInternalLoadDataMerged,
      loadingAesoHistoricalLineChartData,
      expandedTiles,
      logoBase64,
      showSettlementsModal,
    } = this.state;

    const { t, allowData } = this.props;

    const priorDate = new Date(marketDate);
    priorDate.setDate(priorDate.getDate() - 1);
    priorDate.setMinutes(
      priorDate.getMinutes() + priorDate.getTimezoneOffset()
    );

    const historicalChartStartDate = new Date(
      new Date(effectiveDate).getFullYear() - 2,
      0,
      1
    );

    return (
      <div
        className="container-fluid print d-flex flex-column"
        style={{ height: "100%" }}
      >
        <div
          className="row flex-shrink-0 no-print"
          style={{ minHeight: "60px", padding: "8px 0" }}
        >
          <Modal
            show={showSettlementsModal}
            size="xl"
            onHide={this.handleCloseSettlementsModal}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("SettlementsExport")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SettlementsExport marketDate={effectiveDate} />
            </Modal.Body>
          </Modal>

          <div className="d-flex flex-wrap justify-content-between align-items-start w-100 px-3">
            <div style={{ minWidth: "200px", maxWidth: "300px" }}>
              <Input
                type="date"
                min={minDate}
                max={maxDate}
                onKeyDown={(e) => e.preventDefault()}
                onChange={(e) => this.handleDateChange(e.currentTarget.value)}
                value={marketDate}
                label={t("MarketDate")}
              />
            </div>
            <div className="d-flex mt-2 mt-sm-0">
              {allowData && (
                <button
                  className="btn btn-sm btn-secondary me-2"
                  onClick={() => this.handleShowSettlementsModal()}
                  disabled={isLoading}
                >
                  <FontAwesome name="table" /> {this.props.t("Settlements")}
                </button>
              )}
              <button
                className="btn btn-sm btn-secondary"
                onClick={() => this.print()}
                disabled={isLoading || !logoBase64}
              >
                <FontAwesome name="print" /> {this.props.t("Print")}
              </button>
            </div>
          </div>
        </div>
        <div
          className="row flex-grow-1"
          id="alberta-energy-daily-report-content"
          style={{ overflowY: "auto", overflowX: "hidden", minHeight: "0" }}
        >
          <div className="col">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8 d-flex align-items-center justify-content-center">
                <h1>{t("AlbertaEnergyDaily")}</h1>
              </div>
              <div className="col-md-2 align-items-right justify-content-right">
                <img id="logo" src="" alt="" height="40" />{" "}
                {/*This is a placeholder for the image on print - to not remove */}
              </div>
              <div className="col-md-12 d-flex align-items-center justify-content-center">
                <h5>{effectiveDate}</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 tile" style={{ minHeight: "100px" }}>
                <AesoSummaryTable
                  summaryData={aesoSummaryData}
                  currencySymbol={"$"}
                  pricePrecision={2}
                  effectiveDate={effectiveDate}
                  isLoading={loadingAesoSummaryData}
                />
              </div>
              <div className="col-md-4 tile">
                <AbStorageSummaryTable
                  summaryData={abStorageSummary}
                  isLoading={loadingAbStorageSummary}
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-6 tile"
                style={{
                  height: expandedTiles["generationByFuelTypeGraph"]
                    ? 600
                    : 300,
                  position: "relative",
                }}
              >
                <FontAwesome
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={(e) => {
                    this.handleTileExpand("generationByFuelTypeGraph");
                  }}
                  className="clickable"
                  name={
                    expandedTiles["generationByFuelTypeGraph"]
                      ? "down-left-and-up-right-to-center"
                      : "up-right-and-down-left-from-center"
                  }
                />
                <GenerationByFuelTypeGraph
                  data={currentGenerationByFuelType}
                  metricField="net_generation"
                  title={"Generation by Fuel Type"}
                  isLoading={loadingCurrentGenerationByFuelType}
                />
              </div>
              <div
                className="col-md-6 tile"
                style={{
                  height: expandedTiles["reserveByFuelTypeGraph"] ? 600 : 300,
                  position: "relative",
                }}
              >
                <FontAwesome
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={(e) => {
                    this.handleTileExpand("reserveByFuelTypeGraph");
                  }}
                  className="clickable"
                  name={
                    expandedTiles["reserveByFuelTypeGraph"]
                      ? "down-left-and-up-right-to-center"
                      : "up-right-and-down-left-from-center"
                  }
                />
                <GenerationByFuelTypeGraph
                  data={currentGenerationByFuelType}
                  metricField="dispatched_contingency_reserve"
                  title={"Dispatched Contingency Reserve by Fuel Type"}
                  isLoading={loadingCurrentGenerationByFuelType}
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-4 tile"
                style={{
                  height: expandedTiles["sailyElectricityPricingAndLoadGraph"]
                    ? 600
                    : 300,
                  position: "relative",
                }}
              >
                <FontAwesome
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={(e) => {
                    this.handleTileExpand(
                      "sailyElectricityPricingAndLoadGraph"
                    );
                  }}
                  className="clickable"
                  name={
                    expandedTiles["sailyElectricityPricingAndLoadGraph"]
                      ? "down-left-and-up-right-to-center"
                      : "up-right-and-down-left-from-center"
                  }
                />
                <DailyElectricityPricingAndLoadGraph
                  data={historicalPriceInternalLoadDataMerged}
                  isLoading={loadingHistoricalPriceInternalLoadDataMerged}
                />
              </div>
              <div
                className="col-md-4 tile"
                style={{
                  height: expandedTiles[
                    "dailyElectricityPricingAndLoadDeltaGraph"
                  ]
                    ? 600
                    : 300,
                  position: "relative",
                }}
              >
                <FontAwesome
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={(e) => {
                    this.handleTileExpand(
                      "dailyElectricityPricingAndLoadDeltaGraph"
                    );
                  }}
                  className="clickable"
                  name={
                    expandedTiles["dailyElectricityPricingAndLoadDeltaGraph"]
                      ? "down-left-and-up-right-to-center"
                      : "up-right-and-down-left-from-center"
                  }
                />
                <DailyElectricityPricingAndLoadDeltaGraph
                  data={historicalPriceInternalLoadDataMerged}
                  isLoading={loadingHistoricalPriceInternalLoadDataMerged}
                />
              </div>
              <div
                className="col-md-4 tile"
                style={{
                  height: expandedTiles["hourlyElectricityPricingAndLoadGraph"]
                    ? 600
                    : 300,
                  position: "relative",
                }}
              >
                <FontAwesome
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={(e) => {
                    this.handleTileExpand(
                      "hourlyElectricityPricingAndLoadGraph"
                    );
                  }}
                  className="clickable"
                  name={
                    expandedTiles["hourlyElectricityPricingAndLoadGraph"]
                      ? "down-left-and-up-right-to-center"
                      : "up-right-and-down-left-from-center"
                  }
                />
                <HourlyElectricityPricingAndLoadGraph
                  data={priorPriceInternalLoadData}
                  title={"AESO Average Hourly Electricity Pricing and Load"}
                  isLoading={loadingPriorPriceInternalLoadData}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 tile" style={{ minHeight: "300px" }}>
                <SettlementsTable
                  settlements={powerSettles}
                  currencySymbol={"$"}
                  pricePrecision={2}
                  showHeatRate={true}
                  effectiveDate={effectiveDate}
                  title={"7X24 Alberta Forward Power CAD$/MWh (ICE NGX)"}
                  isLoading={loadingPowerSettles}
                />
              </div>
              <div className="col-md-6 tile" style={{ minHeight: "300px" }}>
                <SettlementsTable
                  settlements={gasSettles}
                  currencySymbol={"$"}
                  pricePrecision={2}
                  showHeatRate={false}
                  effectiveDate={effectiveDate}
                  title={"Natural Gas Prices CAD$/GJ (ICE NGX)"}
                  isLoading={loadingGasSettles}
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-6 tile"
                style={{
                  height: expandedTiles["historicalSettlementsGraphPower"]
                    ? 600
                    : 300,
                  position: "relative",
                }}
              >
                <FontAwesome
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={(e) => {
                    this.handleTileExpand("historicalSettlementsGraphPower");
                  }}
                  className="clickable"
                  name={
                    expandedTiles["historicalSettlementsGraphPower"]
                      ? "down-left-and-up-right-to-center"
                      : "up-right-and-down-left-from-center"
                  }
                />
                <HistoricalSettlementsGraph
                  data={calPowerSettles}
                  title={"Alberta Power Forward Calendar Pricing"}
                  initialStartDate={historicalChartStartDate}
                  initialEndDate={new Date(effectiveDate)}
                  isLoading={loadingCalPowerSettles}
                />
              </div>
              <div
                className="col-md-6 tile"
                style={{
                  height: expandedTiles["historicalSettlementsGraphGas"]
                    ? 600
                    : 300,
                  position: "relative",
                }}
              >
                <FontAwesome
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={(e) => {
                    this.handleTileExpand("historicalSettlementsGraphGas");
                  }}
                  className="clickable"
                  name={
                    expandedTiles["historicalSettlementsGraphGas"]
                      ? "down-left-and-up-right-to-center"
                      : "up-right-and-down-left-from-center"
                  }
                />
                <HistoricalSettlementsGraph
                  data={calGasSettles}
                  title={"Alberta Forward Natural Gas Calendar Pricing"}
                  initialStartDate={historicalChartStartDate}
                  initialEndDate={new Date(effectiveDate)}
                  isLoading={loadingCalGasSettles}
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-6 tile"
                style={{
                  height: expandedTiles["naturalGasPoolPriceLineGraph"]
                    ? 600
                    : 300,
                  position: "relative",
                }}
              >
                <FontAwesome
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={(e) => {
                    this.handleTileExpand("naturalGasPoolPriceLineGraph");
                  }}
                  className="clickable"
                  name={
                    expandedTiles["naturalGasPoolPriceLineGraph"]
                      ? "down-left-and-up-right-to-center"
                      : "up-right-and-down-left-from-center"
                  }
                />
                <NaturalGasPoolPriceLineGraph
                  gasData={spotGasSettles}
                  poolPriceData={poolPriceDailyAverages}
                  isLoading={loadingPoolPriceDailyAverages}
                  initialStartDate={historicalChartStartDate}
                  initialEndDate={new Date(effectiveDate)}
                />
              </div>
              <div
                className="col-md-6 tile"
                style={{
                  height: expandedTiles["historicalHeatRateLineGraph"]
                    ? 600
                    : 300,
                  position: "relative",
                }}
              >
                <FontAwesome
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={(e) => {
                    this.handleTileExpand("historicalHeatRateLineGraph");
                  }}
                  className="clickable"
                  name={
                    expandedTiles["historicalHeatRateLineGraph"]
                      ? "down-left-and-up-right-to-center"
                      : "up-right-and-down-left-from-center"
                  }
                />
                <HistoricalHeatRateLineGraph
                  gasData={spotGasSettles}
                  poolPriceData={poolPriceDailyAverages}
                  isLoading={loadingPoolPriceDailyAverages}
                  initialStartDate={historicalChartStartDate}
                  initialEndDate={new Date(effectiveDate)}
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-6 tile"
                style={{
                  height: expandedTiles["aesoYearlyAveragePoolPriceGraph"]
                    ? 600
                    : 300,
                  position: "relative",
                }}
              >
                <FontAwesome
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={(e) => {
                    this.handleTileExpand("aesoYearlyAveragePoolPriceGraph");
                  }}
                  className="clickable"
                  name={
                    expandedTiles["aesoYearlyAveragePoolPriceGraph"]
                      ? "down-left-and-up-right-to-center"
                      : "up-right-and-down-left-from-center"
                  }
                />
                <AesoYearlyAveragePoolPriceGraph
                  poolPriceData={poolPriceDailyAverages}
                  isLoading={loadingPoolPriceDailyAverages}
                />
              </div>
              <div
                className="col-md-6 tile"
                style={{
                  height: expandedTiles["yearlyElectricityPricingAndLoadGraph"]
                    ? 600
                    : 300,
                  position: "relative",
                }}
              >
                <FontAwesome
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={(e) => {
                    this.handleTileExpand(
                      "yearlyElectricityPricingAndLoadGraph"
                    );
                  }}
                  className="clickable"
                  name={
                    expandedTiles["yearlyElectricityPricingAndLoadGraph"]
                      ? "down-left-and-up-right-to-center"
                      : "up-right-and-down-left-from-center"
                  }
                />
                <YearlyElectricityPricingAndLoadGraph
                  priceData={poolPriceDailyAverages}
                  loadData={internalLoadDailyAverages}
                  isLoading={
                    loadingPoolPriceDailyAverages ||
                    loadingInternalLoadDailyAverages
                  }
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-6 tile"
                style={{
                  height: expandedTiles["hourlyForecastLineGraphWind"]
                    ? 600
                    : 300,
                  position: "relative",
                }}
              >
                <FontAwesome
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={(e) => {
                    this.handleTileExpand("hourlyForecastLineGraphWind");
                  }}
                  className="clickable"
                  name={
                    expandedTiles["hourlyForecastLineGraphWind"]
                      ? "down-left-and-up-right-to-center"
                      : "up-right-and-down-left-from-center"
                  }
                />
                <HourlyForecastLineGraph
                  data={longTermWindForecast}
                  title={"AESO Hourly Wind Forecast"}
                  isLoading={loadingLongTermWindForecast}
                />
              </div>
              <div
                className="col-md-6 tile"
                style={{
                  height: expandedTiles["hourlyForecastLineGraphSolar"]
                    ? 600
                    : 300,
                  position: "relative",
                }}
              >
                <FontAwesome
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={(e) => {
                    this.handleTileExpand("hourlyForecastLineGraphSolar");
                  }}
                  className="clickable"
                  name={
                    expandedTiles["hourlyForecastLineGraphSolar"]
                      ? "down-left-and-up-right-to-center"
                      : "up-right-and-down-left-from-center"
                  }
                />
                <HourlyForecastLineGraph
                  data={longTermSolarForecast}
                  title={"AESO Hourly Solar Forecast"}
                  isLoading={loadingLongTermSolarForecast}
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-6 tile"
                style={{
                  height: expandedTiles["dailyForecastBarGraphWind"]
                    ? 600
                    : 300,
                  position: "relative",
                }}
              >
                <FontAwesome
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={(e) => {
                    this.handleTileExpand("dailyForecastBarGraphWind");
                  }}
                  className="clickable"
                  name={
                    expandedTiles["dailyForecastBarGraphWind"]
                      ? "down-left-and-up-right-to-center"
                      : "up-right-and-down-left-from-center"
                  }
                />
                <DailyForecastBarGraph
                  data={longTermWindForecast}
                  title={"AESO Daily Wind Forecast"}
                  isLoading={loadingLongTermWindForecast}
                />
              </div>
              <div
                className="col-md-6 tile"
                style={{
                  height: expandedTiles["dailyForecastBarGraphSolar"]
                    ? 600
                    : 300,
                  position: "relative",
                }}
              >
                <FontAwesome
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={(e) => {
                    this.handleTileExpand("dailyForecastBarGraphSolar");
                  }}
                  className="clickable"
                  name={
                    expandedTiles["dailyForecastBarGraphSolar"]
                      ? "down-left-and-up-right-to-center"
                      : "up-right-and-down-left-from-center"
                  }
                />
                <DailyForecastBarGraph
                  data={longTermSolarForecast}
                  title={"AESO Daily Solar Forecast"}
                  isLoading={loadingLongTermSolarForecast}
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-6 tile"
                style={{
                  height: expandedTiles["dailyStorageLineGraphCanada"]
                    ? 600
                    : 300,
                  position: "relative",
                }}
              >
                <FontAwesome
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={(e) => {
                    this.handleTileExpand("dailyStorageLineGraphCanada");
                  }}
                  className="clickable"
                  name={
                    expandedTiles["dailyStorageLineGraphCanada"]
                      ? "down-left-and-up-right-to-center"
                      : "up-right-and-down-left-from-center"
                  }
                />
                <DailyStorageLineGraph
                  data={abDailyStorage}
                  dateField={"gasDay"}
                  valueField={"inventory"}
                  title={"Alberta Natural Gas Storage Inventory"}
                  isLoading={loadingAbDailyStorage}
                />
              </div>
              <div
                className="col-md-6 tile"
                style={{
                  height: expandedTiles["dailyStorageLineGraphUS"] ? 600 : 300,
                  position: "relative",
                }}
              >
                <FontAwesome
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={(e) => {
                    this.handleTileExpand("dailyStorageLineGraphUS");
                  }}
                  className="clickable"
                  name={
                    expandedTiles["dailyStorageLineGraphUS"]
                      ? "down-left-and-up-right-to-center"
                      : "up-right-and-down-left-from-center"
                  }
                />
                <DailyStorageLineGraph
                  data={usDailyStorage.filter(
                    (x) => x.name === "total lower 48 states"
                  )}
                  dateField={"currentWeek"}
                  valueField={"currentValue"}
                  title={"US Natural Gas Storage Inventory"}
                  isLoading={loadingUsDailyStorage}
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-6 tile"
                style={{
                  height: expandedTiles["dailyPoolPriceLineGraph"] ? 600 : 300,
                  position: "relative",
                }}
              >
                <FontAwesome
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={(e) => {
                    this.handleTileExpand("dailyPoolPriceLineGraph");
                  }}
                  className="clickable"
                  name={
                    expandedTiles["dailyPoolPriceLineGraph"]
                      ? "down-left-and-up-right-to-center"
                      : "up-right-and-down-left-from-center"
                  }
                />
                <DailyPoolPriceLineGraph
                  data={aesoHistoricalLineChartData}
                  isLoading={loadingAesoHistoricalLineChartData}
                />
              </div>
              <div
                className="col-md-6 tile"
                style={{
                  height: expandedTiles["monthlyOutageForecastBarGraph"]
                    ? 600
                    : 300,
                  position: "relative",
                }}
              >
                <FontAwesome
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={(e) => {
                    this.handleTileExpand("monthlyOutageForecastBarGraph");
                  }}
                  className="clickable"
                  name={
                    expandedTiles["monthlyOutageForecastBarGraph"]
                      ? "down-left-and-up-right-to-center"
                      : "up-right-and-down-left-from-center"
                  }
                />
                <MonthlyOutageForecastBarGraph
                  data={monthlyOutageForecast}
                  isLoading={loadingMonthlyOutageForecast}
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-6 tile"
                style={{
                  height: expandedTiles["aesoPoolPriceVsEnmaxRetailLineGraph"]
                    ? 600
                    : 300,
                  position: "relative",
                }}
              >
                <FontAwesome
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={(e) => {
                    this.handleTileExpand(
                      "aesoPoolPriceVsEnmaxRetailLineGraph"
                    );
                  }}
                  className="clickable"
                  name={
                    expandedTiles["aesoPoolPriceVsEnmaxRetailLineGraph"]
                      ? "down-left-and-up-right-to-center"
                      : "up-right-and-down-left-from-center"
                  }
                />
                <AesoPoolPriceVsEnmaxRetailLineGraph
                  poolPriceData={poolPriceDailyAverages}
                  regulatedRateData={regulatedRateOption}
                  rateOfLastResortData={rateOfLastResort}
                  isLoading={
                    loadingPoolPriceDailyAverages ||
                    loadingRegulatedRateOption ||
                    loadingRateOfLastResort
                  }
                  initialStartDate={historicalChartStartDate}
                  initialEndDate={new Date(effectiveDate)}
                />
              </div>
              {/*               <div className="col-md-6 tile" style={{ height: "300px" }}>
                Alberta Retail Pricing Comparison
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(AlbertaPowerReport);
