import React, { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import Loading from "./../../common/components/loading/loading";
import { getOrganizationsByUser } from "./../../common/services/auth/userAccountOrganizationService";
import { getRolesByUser } from "./../../common/services/auth/userAccountRoleService";
import { getUserAccount } from "./../../common/services/auth/userAccountService";
import { getUserAccountSubscriptionsByUserAccount } from "./../../common/services/marketdata/userAccountSubscriptionService";
import Table from "./../../common/components/table/table";
import FontAwesome from "react-fontawesome";
import { usePermitted } from "../../common/components/permissions/permissions";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

const UserProfile = (props) => {
  const { t } = useTranslation(["auth"]);
  const { keycloak, initialized } = useKeycloak();
  const [subscriptions, setSubscriptions] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [roles, setRoles] = useState([]);
  const [userAccount, setUserAccount] = useState(null);

  const queryUserAccounts = usePermitted(["api-query-user-accounts"]);
  const queryOrganizations = usePermitted(["api-query-organizations"]);
  const queryRoles = usePermitted(["api-query-roles"]);
  const queryUserAccountSubscriptions = usePermitted([
    "api-user-account-market-subscriptions",
  ]);
  const allowThemeSwitching = usePermitted(["administrator", "operator"]);

  const userAccountId = keycloak.tokenParsed.OriginatingUserAccountID;
  const kcUserAccountId = keycloak.tokenParsed.sub;
  const kcLogin = keycloak.tokenParsed.preferred_username;
  const kcLastName = keycloak.tokenParsed.family_name;
  const kcFirstName = keycloak.tokenParsed.given_name;
  const kcEmail = keycloak.tokenParsed.email;

  const orgColumns = [
    { path: "name", label: t("Organization") },
    {
      path: "inClaims",
      label: t("InClaims"),
      content: (organization) => (
        <FontAwesome
          style={{ color: organization.inClaims ? "green" : "red" }}
          name={organization.inClaims ? "check" : "times"}
        />
      ),
    },
  ];

  const roleColumns = [
    { path: "name", label: t("Role") },
    {
      path: "inClaims",
      label: t("InClaims"),
      content: (role) => (
        <FontAwesome
          style={{ color: role.inClaims ? "green" : "red" }}
          name={role.inClaims ? "check" : "times"}
        />
      ),
    },
  ];

  const subscriptionColumns = [
    { path: "organizationSubscriptionName", label: t("Subscription") },
    { path: "type", label: t("SubscriptionType") },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (queryUserAccounts) {
          const result = await getUserAccount(userAccountId);
          setUserAccount(result);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [queryUserAccounts, userAccountId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (queryOrganizations) {
          const result = await getOrganizationsByUser(userAccountId);
          setOrganizations(
            result
              .filter((x) => x.active)
              .map((organization) => ({
                ...organization,
                inClaims: keycloak.tokenParsed.group.includes(
                  organization.organizationId
                ),
              }))
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [queryOrganizations, userAccountId, keycloak.tokenParsed.group]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (queryRoles) {
          const result = await getRolesByUser(userAccountId);
          setRoles(
            result
              .filter((x) => x.active)
              .map((role) => ({
                ...role,
                inClaims: keycloak.tokenParsed.role.includes(role.name),
              }))
          );
        }
        //console.log(result); // You can use 'result' here if needed
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [queryRoles, userAccountId, keycloak.tokenParsed.role]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (queryUserAccountSubscriptions) {
          const result = await getUserAccountSubscriptionsByUserAccount(
            userAccountId
          );
          setSubscriptions(
            result
              .filter((x) => x.active)
              .map((subscription) => ({
                ...subscription,
                type: subscription.contributor ? t("Contributor") : t("Viewer"),
              }))
          );
        }
        //console.log(result); // You can use 'result' here if needed
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [queryUserAccountSubscriptions, userAccountId, t]); // The empty dependency array ensures that this effect runs once on component mount

  // useEffect(() => {
  //   console.log(subscriptions);
  // }, [subscriptions]);

  const roleList = keycloak.tokenParsed.role.map((role) => (
    <span style={{ fontSize: 10, color: "var(--theme-background)" }} key={role}>
      {role},{" "}
    </span>
  ));

  //  const orgList = keycloak.tokenParsed.group.map((organization) =>
  //     <li key={organization}>{organization}</li>
  //     );

  // console.log("auth_time:",new Date(keycloak.tokenParsed.auth_time))
  // console.log("exp",new Date(keycloak.tokenParsed.exp))
  // console.log("iat",new Date(keycloak.tokenParsed.iat))

  const handleSetTheme = async (theme) => {
    //This is where we'll amek the API call to persisit the setting.
    //TODO

    //Call back to App.js to apply the setting in the current session.
    props.setTheme(theme);
  };

  if (!initialized) {
    return <Loading />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <h3>{t("UserProfile")}</h3>
          <div className="row">
            <div className="col-md-2">
              <h5>{t("Login")}:</h5>
            </div>
            <div className="col-md-10">
              <h6>{(userAccount && userAccount.login) ?? kcLogin}</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <h5>{t("FirstName")}:</h5>
            </div>
            <div className="col-md-10">
              <span>
                {(userAccount && userAccount.firstName) ?? kcFirstName}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <h5>{t("LastName")}:</h5>
            </div>
            <div className="col-md-10">
              <span>{(userAccount && userAccount.lastName) ?? kcLastName}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <h5>{t("Email")}:</h5>
            </div>
            <div className="col-md-10">
              <span>{(userAccount && userAccount.email) ?? kcEmail}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <h5>{t("UserAccountId")}:</h5>
            </div>
            <div className="col-md-10">
              <span>
                {(userAccount && userAccount.userAccountId) ??
                  userAccountId ??
                  kcUserAccountId + "*"}
              </span>
            </div>
          </div>
          {allowThemeSwitching && (
            <div className="row">
              <div className="col-md-2">
                <h5>{t("Theme")}:</h5>
              </div>
              <div className="col-md-10">
                <ButtonGroup className="mb-2">
                  <ToggleButton
                    size="sm"
                    key={"DarkMode"}
                    id={"DarkMode"}
                    type="radio"
                    variant="secondary"
                    name="radio"
                    value={"dark"}
                    checked={props.theme === "dark"}
                    onChange={(e) => handleSetTheme(e.currentTarget.value)}
                  >
                    {t("DarkMode")}
                  </ToggleButton>
                  <ToggleButton
                    size="sm"
                    key={"LightMode"}
                    id={"LightMode"}
                    type="radio"
                    variant="secondary"
                    name="radio"
                    value={"light"}
                    checked={props.theme === "light"}
                    onChange={(e) => handleSetTheme(e.currentTarget.value)}
                  >
                    {t("LightMode")}
                  </ToggleButton>
                </ButtonGroup>
              </div>
            </div>
          )}
        </div>
        {/* <div className="col-md-6">
      <h3>{t("UserAccountId")}: {keycloak.tokenParsed.OriginatingUserAccountID}</h3>
    </div>           */}
      </div>

      <div className="row">
        <div className="col-md-4">
          <h4>{t("Organizations")}</h4>
          {queryOrganizations ? (
            organizations !== null ? (
              <Table
                className="table-bold"
                columns={orgColumns}
                sortColumn="name"
                data={organizations || []}
                valueProperty="organizationId"
              />
            ) : (
              <Loading />
            )
          ) : (
            <p className="text-muted">{t("NoPermission")}</p>
          )}
        </div>
        <div className="col-md-4">
          <h4>{t("Roles")}</h4>
          {queryRoles ? (
            roles !== null ? (
              <Table
                className="table-bold"
                columns={roleColumns}
                sortColumn="name"
                data={roles || []}
                valueProperty="roleId"
              />
            ) : (
              <Loading />
            )
          ) : (
            <p className="text-muted">{t("NoPermission")}</p>
          )}
        </div>
        <div className="col-md-4">
          <h4>{t("Subscriptions")}</h4>
          {queryUserAccountSubscriptions ? (
            subscriptions !== null ? (
              <Table
                className="table-bold"
                columns={subscriptionColumns}
                sortColumn="organizationSubscriptionName"
                data={subscriptions || []}
                valueProperty="organizationSubscriptionId"
              />
            ) : (
              <Loading />
            )
          ) : (
            <p className="text-muted">{t("NoPermission")}</p>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">{roleList}</div>
      </div>
    </div>
  );
};

export default UserProfile;
