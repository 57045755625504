import React from "react";
import PropTypes from "prop-types";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";
import _ from "lodash";

/**
 * Table component for displaying tabular data
 * Includes built-in safety measures to prevent "Cannot read properties of null" errors
 */
const Table = ({
  columns,
  sortColumn,
  onSort,
  data = [], // Default to empty array to prevent null/undefined errors
  valueProperty = "_id",
  groupingProperty,
  onRowClick,
  onMouseEnter,
  onMouseLeave,
  classProperty,
  className = "",
  ...rest
}) => {
  // Ensure data is always an array even if null is passed
  // This is a critical safety measure to prevent runtime errors
  const safeData = data || [];

  // Move grouping logic to separate function for clarity
  const getGroupedData = () => {
    // Safe filtering with proper null checking on groupingProperty
    const groupingValues = _.uniqBy(
      safeData.filter((obj) => obj && obj[groupingProperty]),
      groupingProperty
    ).map((obj) => obj[groupingProperty]);

    // Filter out items that are in groups to get ungrouped data
    const ungroupedData = safeData.filter(
      (d) => !groupingValues.includes(d[groupingProperty])
    );

    return { groupingValues, ungroupedData };
  };

  // Get grouped or ungrouped data based on groupingProperty
  // Fall back to default values for null/undefined cases
  const { groupingValues, ungroupedData } = groupingProperty
    ? getGroupedData()
    : { groupingValues: [], ungroupedData: safeData };

  return (
    <div className="table-responsive">
      <table className={`table-base ${className}`} {...rest}>
        <TableHeader
          columns={columns}
          sortColumn={sortColumn}
          onSort={onSort}
        />

        {/* Render grouped data items */}
        {groupingValues.map((groupValue) => (
          <TableBody
            key={groupValue}
            data={safeData.filter((d) => d[groupingProperty] === groupValue)}
            columns={columns}
            classProperty={classProperty}
            onRowClick={onRowClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            valueProperty={valueProperty}
          />
        ))}

        {/* Only render ungrouped data if it exists to prevent empty tbody */}
        {ungroupedData.length > 0 && (
          <TableBody
            data={ungroupedData}
            columns={columns}
            classProperty={classProperty}
            onRowClick={onRowClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            valueProperty={valueProperty}
          />
        )}
      </table>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  sortColumn: PropTypes.object,
  onSort: PropTypes.func,
  valueProperty: PropTypes.string,
  groupingProperty: PropTypes.string,
  onRowClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  classProperty: PropTypes.string,
  className: PropTypes.string,
};

export default Table;
